import {
  Root,
  Trigger,
  Value,
  Portal,
  Content,
  ScrollDownButton,
  Viewport,
  ScrollUpButton,
  Item,
  ItemText,
  Icon,
} from "@radix-ui/react-select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import css from "./Select.module.css";
import { ReactNode } from "react";

type Variant = "primary" | "secondary" | "tertiary";
type Size = "sm" | "lg";

export type SelectOption<T> = { value: T; label: string; slot?: ReactNode };

interface Props<T extends string> {
  value: T;
  variant: Variant;
  onChange: (value: T) => void;
  options: SelectOption<T>[];
  size?: Size;
  label?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

export function Select<T extends string>({
  variant,
  value,
  onChange,
  options,
  label,
  size = "sm",
  disabled = false,
  className,
  placeholder,
}: Props<T>) {
  return (
    <div className={className}>
      {!!label && <p className={"typo-text-xs-regular text-gray-500 mb-0.5"}>{label}</p>}
      <Root value={value} onValueChange={onChange}>
        <Trigger
          disabled={disabled}
          className={clsx(css.trigger, variantMap[variant], size === "lg" && "h-12 border-gray-300 justify-between")}
          aria-label={value}
        >
          <Value className={"placeholder:font-thin placeholder:text-gray-500"} placeholder={placeholder} />
          <Icon>
            <FontAwesomeIcon icon={faChevronDown} />
          </Icon>
        </Trigger>

        <Portal>
          <Content position={"popper"} sideOffset={5} className={css.content}>
            <ScrollUpButton />
            <Viewport>
              {options.map(({ value, label, slot }) => (
                <Item className={css.item} key={value} value={value}>
                  {slot}
                  <ItemText>{label}</ItemText>
                </Item>
              ))}
            </Viewport>
            <ScrollDownButton />
          </Content>
        </Portal>
      </Root>
    </div>
  );
}

const variantMap: Record<Variant, string> = {
  primary: css.primary,
  secondary: css.secondary,
  tertiary: css.tertiary,
};
