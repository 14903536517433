import { clsx } from "clsx";
import css from "./Badge.module.css";

type Size = "sm" | "md";
type Color = "brand" | "gray" | "secondary" | "blue";

interface Props {
  text: string;
  size: Size;
  color: Color;
}

export function Badge({ text, size, color }: Props) {
  return (
    <div className={clsx(css.badge, sizeMap[size], colorMap[color])}>
      <p>{text}</p>
    </div>
  );
}

const sizeMap: Record<Size, string> = {
  sm: css.small,
  md: css.medium,
};

const colorMap: Record<Color, string> = {
  brand: css.brand,
  gray: css.gray,
  secondary: css.secondary,
  blue: css.blue,
};
