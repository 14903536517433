import { CONFIG } from "../../utils/config.ts";
import { useQuery } from "@tanstack/react-query";
import { api } from "../ky.ts";

export const STATUS_QUERY_KEY = "status";
const { API_URL } = CONFIG;

export function useStatusQuery() {
  return useQuery({
    queryKey: [STATUS_QUERY_KEY],
    queryFn: async () => {
      return await api.get(API_URL + "/multibank/users/me/status", { retry: 0 }).json();
    },
    refetchOnWindowFocus: true,
    retry: false,
  });
}
