import {
  Close,
  Content,
  Description,
  DialogProps,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from "@radix-ui/react-dialog";
import css from "./Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../IconButton/IconButton.tsx";
import { ReactNode } from "react";
import { clsx } from "clsx";
import { Tooltip } from "../Tooltip/Tooltip.tsx";

interface Props extends Pick<DialogProps, "open" | "onOpenChange"> {
  children: ReactNode;
  title: string;
  trigger?: ReactNode;
  icon?: ReactNode;
  className?: string;
}

export function Modal({ icon, children, title, trigger, open, onOpenChange, className }: Props) {
  return (
    <Root open={open} onOpenChange={onOpenChange}>
      {!!trigger && <Trigger asChild>{trigger}</Trigger>}
      <Portal>
        <Overlay className={css.overlay} />
        <Content onOpenAutoFocus={(e) => e.preventDefault()} className={clsx(css.content, className)}>
          <VisuallyHidden asChild>
            <Description>{title}</Description>
          </VisuallyHidden>
          <VisuallyHidden asChild></VisuallyHidden>
          <div className={css.modalHeader}>
            <div className={"flex items-center"}>
              {icon}
              <Title asChild>
                <h3 className={css.modalHeadline}>{title}</h3>
              </Title>
            </div>
            <Tooltip
              trigger={
                <Close asChild>
                  <IconButton
                    className={"fixed top-2.5 right-2.5"}
                    variant={"default"}
                    color={"gray"}
                    size={"md"}
                    icon={<FontAwesomeIcon icon={faXmark} />}
                  />
                </Close>
              }
              text={"Zavřít"}
            />
          </div>
          <div>{children}</div>
        </Content>
      </Portal>
    </Root>
  );
}
