import { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { Transaction } from "../../../dataProviders/TransactionsDataProvider.tsx";
import { addDays, isAfter, isBefore, subDays } from "date-fns";
import { getCounterPartyInfo } from "../../../utils/tableHelpers.tsx";
import { usePaginationReset } from "../../../components/Pagination/usePagination.ts";
import { useUrlParams } from "../../../router/useUrlParams.ts";

const SEARCH_URL_PARAM = "search";

const ACCOUNT_URL_PARAM = "account";
const ACCOUNT_DEFAULT_VALUE = "all";

const DIRECTION_URL_PARAM = "direction";
const DIRECTION_DEFAULT_VALUE = "all";

const STATUS_URL_PARAM = "status";
const STATUS_DEFAULT_VALUE = "all";

const START_URL_PARAM = "start";
const START_DEFAULT_VALUE = null;

const END_URL_PARAM = "end";
const END_DEFAULT_VALUE = null;

export function useFilter(transactions: Transaction[]) {
  const params = useUrlParams();
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const { resetPagination } = usePaginationReset();
  const resetPaginationRef = useRef(resetPagination);

  const [search, setSearch] = useState(() => params.get(SEARCH_URL_PARAM) || "");
  const debouncedSearch = useDebounce(search, 500);

  const initialSearchRef = useRef(search);
  const searchChangedRef = useRef(false);

  useEffect(() => {
    if (debouncedSearch !== initialSearchRef.current) searchChangedRef.current = true;
    if (!searchChangedRef.current) return;

    debouncedSearch
      ? paramsRef.current.set(SEARCH_URL_PARAM, debouncedSearch)
      : paramsRef.current.remove(SEARCH_URL_PARAM);
    resetPaginationRef.current();
  }, [debouncedSearch]);

  const [filterAccounts, setFilterAccounts] = useState(() => params.get(ACCOUNT_URL_PARAM) || ACCOUNT_DEFAULT_VALUE);
  const [filterDirection, setFilterDirection] = useState(
    () => params.get(DIRECTION_URL_PARAM) || DIRECTION_DEFAULT_VALUE,
  );
  const [filterStatus, setFilterStatus] = useState(() => params.get(STATUS_URL_PARAM) || STATUS_DEFAULT_VALUE);
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const value = params.get(START_URL_PARAM);
    return value ? new Date(value) : START_DEFAULT_VALUE;
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const value = params.get(END_URL_PARAM);
    return value ? new Date(value) : END_DEFAULT_VALUE;
  });

  const changeFilterAccounts = (value: typeof filterAccounts) => {
    value !== ACCOUNT_DEFAULT_VALUE ? params.set(ACCOUNT_URL_PARAM, value) : params.remove(ACCOUNT_URL_PARAM);
    setFilterAccounts(value);
    resetPagination();
  };

  const changeFilterDirection = (value: typeof filterDirection) => {
    value !== DIRECTION_DEFAULT_VALUE ? params.set(DIRECTION_URL_PARAM, value) : params.remove(DIRECTION_URL_PARAM);
    setFilterDirection(value);
    resetPagination();
  };

  const changeFilterStatus = (value: typeof filterStatus) => {
    value !== STATUS_DEFAULT_VALUE ? params.set(STATUS_URL_PARAM, value) : params.remove(STATUS_URL_PARAM);
    setFilterStatus(value);
    resetPagination();
  };

  const changeFilterStart = (value: typeof startDate) => {
    value ? params.set(START_URL_PARAM, value.toISOString()) : params.remove(START_URL_PARAM);
    setStartDate(value);
    resetPagination();
  };

  const changeFilterEnd = (value: typeof endDate) => {
    value ? params.set(END_URL_PARAM, value.toISOString()) : params.remove(END_URL_PARAM);
    setEndDate(value);
    resetPagination();
  };

  const filteredTransactions = useMemo(() => {
    if (!transactions) return [];

    const items: typeof transactions = [];

    for (const transaction of transactions) {
      if (debouncedSearch.length && !isSearchMatch(debouncedSearch, transaction)) continue;
      if (filterAccounts !== "all" && transaction.account !== filterAccounts) continue;
      if (filterDirection !== "all" && transaction.creditDebitIndicator !== filterDirection) continue;
      if (filterStatus !== "all" && transaction.status !== filterStatus) continue;
      if (startDate && !isAfter(transaction.date, subDays(startDate, 1))) continue;
      if (endDate && !isBefore(transaction.date, addDays(endDate, 1))) continue;

      items.push(transaction);
    }

    return items;
  }, [debouncedSearch, endDate, filterAccounts, filterDirection, filterStatus, startDate, transactions]);

  return {
    filteredTransactions,
    search,
    setSearch,
    filterAccounts,
    changeFilterAccounts,
    filterDirection,
    changeFilterDirection,
    filterStatus,
    changeFilterStatus,
    startDate,
    changeFilterStart,
    endDate,
    changeFilterEnd,
  };
}

function isSearchMatch(search: string, transaction: Transaction): boolean {
  const { iban, name, accountName } = getCounterPartyInfo(transaction);
  const message =
    transaction.entryDetails?.transactionDetails?.additionalRemittanceInformation ||
    transaction.entryDetails?.transactionDetails?.remittanceInformation?.unstructured;
  let value = accountName ?? "";
  value += iban ?? "";
  value += name ?? "";
  value += message ?? "";
  value += transaction.identification.join(",");

  return sanitizeSearch(value).includes(sanitizeSearch(search));
}

function sanitizeSearch(value: string): string {
  return value
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
}
