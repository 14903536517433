import { useContext, useEffect, useState } from "react";
import { Divider, ScrollArea, Tabs } from "../../../../components";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { formatNumber } from "../../../../utils/format.ts";
import { BalanceDevelopmentData, getAccountRelativeBalanceValue } from "./utils.ts";
import { TabData } from "./BalanceDevelopment.tsx";
import { getAccountName } from "../../../../utils/tableHelpers.tsx";

interface Props {
  accountDisplay: TabData[number]["value"];
  dataset: BalanceDevelopmentData[];
}

export function BalanceDevelopmentTable({ dataset, accountDisplay }: Props) {
  const { activeCurrency, listOfActiveAccounts, accountsData } = useContext(DataContext);
  const [selectedAccount, setSelectedAccount] = useState(listOfActiveAccounts[0]);

  useEffect(() => setSelectedAccount(listOfActiveAccounts[0]), [listOfActiveAccounts]);

  return (
    <div>
      {accountDisplay === "total" && (
        <ScrollArea orientation={"vertical"} className={"max-h-[25.5rem]"}>
          {dataset.map((item, index) => {
            const isLastItem = index === dataset.length - 1;

            return (
              <div key={item.label} className={"mt-6"}>
                <div className={"flex gap-2 justify-between typo-display-xxs-medium"}>
                  <p>{item.label}</p>
                  <div className={"flex gap-2"}>
                    <span>{activeCurrency}</span>
                    <p>{formatNumber(item.relativeBalance)}</p>
                  </div>
                </div>
                {!isLastItem && <Divider className={"mt-4 mb-4"} />}
              </div>
            );
          })}
        </ScrollArea>
      )}
      {accountDisplay === "detail" && (
        <div>
          <div className={"flex gap-6 flex-wrap"}>
            {listOfActiveAccounts.map((account) => (
              <Tabs
                key={account}
                variant={"text"}
                isActive={selectedAccount === account}
                onClick={() => setSelectedAccount(account)}
              >
                {getAccountName(accountsData[account])}
              </Tabs>
            ))}
          </div>
          <ScrollArea orientation={"vertical"} className={"max-h-[25.5rem]"}>
            {dataset.map((item, index) => {
              const isLastItem = index === dataset.length - 1;

              return (
                <div key={item.label} className={"mt-6"}>
                  <div className={"flex gap-2 justify-between typo-display-xxs-medium"}>
                    <p>{item.label}</p>
                    <div className={"flex gap-2"}>
                      <span>{activeCurrency}</span>
                      <p>{formatNumber(getAccountRelativeBalanceValue(selectedAccount, item))}</p>
                    </div>
                  </div>
                  {!isLastItem && <Divider className={"mt-4 mb-4"} />}
                </div>
              );
            })}
          </ScrollArea>
        </div>
      )}
    </div>
  );
}
