import { Select } from "../../../components";
import { useContext } from "react";
import { DataContext } from "../../../dataProviders/DataProvider.tsx";

export function CountryAndCurrencySelect() {
  const {
    structuredData,
    currenciesSelect,
    activeCurrency,
    setActiveCurrency,
    activeCountry,
    setActiveCountry,
    countriesSelectAllOption,
    storage,
  } = useContext(DataContext);

  const activeCountryValue = countriesSelectAllOption.length === 2 ? countriesSelectAllOption[1].value : activeCountry;

  return (
    <div className={"flex gap-4"}>
      <Select
        variant={"secondary"}
        value={activeCountryValue}
        disabled={countriesSelectAllOption.length <= 2}
        options={countriesSelectAllOption}
        onChange={(value) => {
          setActiveCountry(value);
          storage?.changeCountry(value);

          if (value !== "all" && !structuredData[value][activeCurrency]) {
            const currency = Object.keys(structuredData[value])[0];
            setActiveCurrency(currency);
            storage?.changeCurrency(currency);
          }
        }}
        className={"min-w-52"}
      />

      <Select
        disabled={currenciesSelect.length <= 1}
        variant={"secondary"}
        value={activeCurrency}
        options={currenciesSelect}
        onChange={(value) => {
          setActiveCurrency(value);
          storage?.changeCurrency(value);
        }}
      />
    </div>
  );
}
