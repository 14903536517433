import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";
import css from "./IconButton.module.css";
import { clsx } from "clsx";

type Variant = "default" | "destructive";
type Color = "primary" | "secondary" | "tertiary" | "gray";
type Size = "sm" | "md" | "lg" | "xl";

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "title"> {
  variant: Variant;
  color: Color;
  size: Size;
  icon: ReactNode;
  title?: string;
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(function IconButton(
  { variant, color, size, icon, className, title, ...props },
  ref,
) {
  return (
    <button
      ref={ref}
      className={clsx(css.button, variantMap[variant], colorMap[color], sizeMap[size], className)}
      {...props}
      title={title}
    >
      <div className={css.iconContainer}>{icon}</div>
    </button>
  );
});

const variantMap: Record<Variant, string> = {
  default: css.default,
  destructive: css.destructive,
};

const colorMap: Record<Color, string> = {
  primary: css.primary,
  secondary: css.secondary,
  tertiary: css.tertiary,
  gray: css.gray,
};

const sizeMap: Record<Size, string> = {
  sm: css.sm,
  md: css.md,
  lg: css.lg,
  xl: css.xl,
};
