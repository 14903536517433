import { ReactNode } from "react";
import css from "./Card.module.css";
import { clsx } from "clsx";

type Variant = "light" | "medium" | "dark";
type Size = "sm" | "md";

interface Props {
  children: ReactNode;
  variant: Variant;
  size: Size;
  className?: string;
}
export function Card({ children, size, variant, className }: Props) {
  return <div className={clsx(css.card, variantMap[variant], sizeMap[size], className)}>{children}</div>;
}

const variantMap: Record<Variant, string> = {
  light: css.light,
  medium: css.medium,
  dark: css.dark,
};

const sizeMap: Record<Size, string> = {
  sm: css.sm,
  md: css.md,
};
