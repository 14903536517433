import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { BalanceOverviewTransactionsChart } from "./BalanceOverviewTransactionsChart.tsx";
import { formatNumber } from "../../../../utils/format.ts";

interface Props {
  currency: string;
  balance: number;
}

export function BalanceOverviewTransactions({ currency, balance }: Props) {
  return (
    <section>
      <h3 className={"typo-display-xs-semibold mt-14 mb-4"}>Disponibilní zůstatek</h3>
      <div className={"flex items-center mb-6"}>
        <FontAwesomeIcon className={"w-12 h-12 text-secondary-500 mr-6"} icon={faWallet} />
        <span className={"typo-display-lg-bold mr-2"}>{formatNumber(balance)}</span>
        <span className={"typo-display-md-regular"}>{currency}</span>
      </div>
      <BalanceOverviewTransactionsChart />
    </section>
  );
}
