import { forwardRef, HTMLProps, InputHTMLAttributes, LabelHTMLAttributes, ReactNode } from "react";
import { faTriangleExclamation, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { clsx } from "clsx";
import css from "./Input.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type Variant = "error" | "info";

export interface Message {
  text: string;
  variant: Variant;
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
  label?: string;
  isError?: boolean;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  messages?: Message[];
  variant?: "default" | "high-contrast";
  containerProps?: HTMLProps<HTMLDivElement>;
}

export const Input = forwardRef<HTMLInputElement, Props>(function Input(
  {
    id,
    label,
    isError,
    labelProps,
    messages,
    disabled,
    placeholder,
    className,
    containerProps,
    required,
    icon,
    variant = "default",
    ...props
  },
  ref,
) {
  return (
    <div {...containerProps} className={clsx("relative", containerProps?.className)}>
      {!!label && (
        <label
          className={clsx(css.label, disabled && css.labelDisabled, required && css.labelRequired)}
          htmlFor={id}
          {...labelProps}
        >
          {label}
        </label>
      )}

      <input
        ref={ref}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        className={clsx(
          className,
          css.input,
          variant === "high-contrast" && css.inputHighContrast,
          isError && css.inputError,
          !!icon && "pr-10",
        )}
        {...props}
      />

      {!!icon && <div className={"w-6 h-6 text-gray-700 absolute right-2 top-3"}>{icon}</div>}

      {messages?.map(({ text, variant }) => (
        <div
          className={clsx(css.message, variant === "error" && css.errorMessage, disabled && css.messageDisabled)}
          key={text}
        >
          <FontAwesomeIcon icon={variant === "error" ? faTriangleExclamation : faCircleInfo} width={16} height={16} />
          {text}
        </div>
      ))}
    </div>
  );
});
