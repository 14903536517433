import { Root, Content, Portal, Trigger, Arrow, Provider, TooltipContentProps } from "@radix-ui/react-tooltip";
import { ReactNode } from "react";

interface Props extends Pick<TooltipContentProps, "side"> {
  trigger: ReactNode;
  text: string;
}

export function Tooltip({ text, trigger, side }: Props) {
  return (
    <Provider delayDuration={400}>
      <Root>
        <Trigger asChild>{trigger}</Trigger>
        <Portal>
          <Content
            className="data-[state=instant-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade typo-text-sm-medium text-gray-25 select-none rounded bg-gray-800 p-3 text-[15px] leading-none shadow-[0px_2px_18px_0px_rgba(16,_24,_40,_0.05)] will-change-[transform,opacity]"
            sideOffset={5}
            side={side}
          >
            {text}
            <Arrow className={"fill-gray-800"} />
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
}
