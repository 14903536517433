import { formatNumber } from "../../../utils/format.ts";

type Values = { value: number; color: string };

interface Props {
  label: string;
  values: Values[];
  currency: string;
}

export function MultipleValuesChartTooltip({ label, values, currency }: Props): JSX.Element {
  return (
    <div className={"ml-4 rounded-lg bg-gray-25 p-4 min-w-48 shadow-[0px_2px_16px_2px_rgba(12,_11,_50,_0.15)]"}>
      <p className={"typo-text-sm-regular text-gray-500 mb-2"}>{label}</p>
      {values.map((value) => (
        <div key={`${value.value}_${value.color}`} className="flex items-center gap-2">
          <div className={"w-6 h-4 rounded"} style={{ backgroundColor: value.color }}></div>
          <p className={"typo-text-xl-bold text-gray-700 pr-2"}>{formatNumber(value.value)}</p>
          <span className={"typo-text-lg-medium text-gray-700 ml-auto"}>{currency}</span>
        </div>
      ))}
    </div>
  );
}
