import { CONFIG } from "../utils/config.ts";
import { ApiEndpoint } from "./types.ts";

const { API_URL } = CONFIG;

export function getApiUrl(endpoint: ApiEndpoint, params: Record<string, string> = {}): string {
  for (const param of Object.keys(params)) {
    endpoint = endpoint.replace(`{${param}}`, params[param]) as ApiEndpoint;
  }
  return API_URL + endpoint;
}
