type AccountColors = Record<number, string[]>;
export const bankColors = ["#1E4631", "#210E8E", "#851651", "#93370D", "#194185", "#2E3214", "#7A271A"];

export const accountColors: AccountColors = {
  0: ["#18EC9E", "#23A771", "#77F2B6", "#216244", "#20CF8B", "#BDF9D8", "#247C55"],
  1: ["#948BF6", "#5650C9", "#BBBAF9", "#2A13BD", "#725DF2", "#D2D1FB", "#3518EC"],
  2: ["#F670C7", "#DD2590", "#FAA7E0", "#9E165F", "#EE46BC", "#FCCEEE", "#C11574"],
  3: ["#FEC84B", "#F79009", "#FEDF89", "#B54708", "#FDB022", "#FEF0C7", "#DC6803"],
  4: ["#84CAFF", "#2E90FA", "#B2DDFF", "#175CD3", "#53B1FD", "#D1E9FF", "#1570EF"],
  5: ["#B7CF1C", "#6F7C1D", "#D0EC18", "#434918", "#9DB11B", "#E5F37E", "#58621B"],
  6: ["#F97066", "#D92D20", "#FDA29B", "#912018", "#F04438", "#FECDCA", "#B42318"],
};

export const balanceOverviewCurve = "#210e8e";
export const balanceOverviewHighlight = "#3518ec";
export const primaryChartColor = "#725DF2";
export const secondaryChartColor = "#18EC9E";
export const axisColor = "#d0d5dd";
export const tickColor = "#667085";

export const noDataMessage = "Pro dané období nejsou k dispozici žádná data";
