import { ReactNode } from "react";
import { Root, Thumb, Scrollbar, Viewport } from "@radix-ui/react-scroll-area";
import { useMeasure } from "@uidotdev/usehooks";
import { clsx } from "clsx";
import css from "./ScrollArea.module.css";

type Color = "light" | "background" | "dark";

interface Props {
  orientation: "horizontal" | "vertical";
  children: ReactNode;
  color?: Color;
  className?: string;
}

export function ScrollArea({ orientation, children, className, color = "light" }: Props) {
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure();
  const [contentRef, { width: contentWidth, height: contentHeight }] = useMeasure();

  const showHorizontalGradient = orientation === "horizontal" && (contentWidth ?? 0) > (containerWidth ?? 0);
  const showVerticalGradient = orientation === "vertical" && (contentHeight ?? 0) > (containerHeight ?? 0);
  const showGradient = showHorizontalGradient || showVerticalGradient;

  return (
    <Root type={"hover"} className={clsx("flex relative", className)}>
      <Viewport ref={containerRef} className={"w-full"}>
        <div className={clsx(showHorizontalGradient && "pr-6", showVerticalGradient && "pb-6")}>
          <div ref={contentRef}>{children}</div>
        </div>
      </Viewport>
      <Scrollbar className={css.scrollBar} orientation={orientation}>
        <Thumb
          className={clsx(
            css.thumb,
            thumbColorMap[color],
            orientation === "horizontal" ? css.thumbHorizontal : css.thumbVertical,
          )}
        />
      </Scrollbar>
      {showGradient && (
        <div
          className={clsx(
            css.gradient,
            colorMap[color],
            showHorizontalGradient && "top-0 w-8 bg-gradient-to-l",
            showVerticalGradient && "left-0 h-8 bg-gradient-to-t",
          )}
        />
      )}
    </Root>
  );
}

const colorMap: Record<Color, string> = {
  light: css.light,
  background: css.background,
  dark: css.dark,
};

const thumbColorMap: Record<Color, string> = {
  light: css.thumbLight,
  background: css.thumbDark,
  dark: css.thumbDark,
};
