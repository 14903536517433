import { createContext, ReactNode, useContext, useMemo, useRef, useState } from "react";
import { useTransactionQuery } from "../api";
import { DataContext, PaymentCount } from "./DataProvider.tsx";
import { isAfter, subDays } from "date-fns";
import { AccountTransactionsResponse } from "../api";
import { formatResponse } from "../api/helperFunctions.ts";
import { getAccountName } from "../utils/tableHelpers.tsx";
import { logError } from "../utils/sentry.ts";

interface Props {
  children: ReactNode;
}

type ApiTransaction = AccountTransactionsResponse["transactions"][number];

export type Transaction = ApiTransaction & {
  identification: string[];
  account: string;
  date: Date;
};

type TransactionRequest = { accountId: string; label: string; response: string }[];

const defaultTransactionRequest: TransactionRequest = [];

interface Context {
  transactions: Transaction[];
  areTransactionsQueriesPending: boolean;
  areTransactionsQueriesError: boolean;
  transactionsOfActiveAccounts: Transaction[];
  incomingPaymentCount: PaymentCount;
  outgoingPaymentCount: PaymentCount;
  identifiedTransaction: Record<string, AccountTransactionsResponse["transactions"]>;
  incomingPaymentTimeRange: string;
  outgoingPaymentTimeRange: string;
  setIncomingPaymentTimeRange: (incomingPaymentTimeRange: string) => void;
  setOutgoingPaymentTimeRange: (outgoingPaymentTimeRange: string) => void;
  transactionRequest: TransactionRequest;
}

export const TransactionsDataContext = createContext<Context>({
  transactions: [],
  areTransactionsQueriesPending: true,
  areTransactionsQueriesError: false,
  transactionsOfActiveAccounts: [],
  incomingPaymentCount: {},
  outgoingPaymentCount: {},
  identifiedTransaction: {},
  incomingPaymentTimeRange: "30",
  outgoingPaymentTimeRange: "30",
  setIncomingPaymentTimeRange: () => {},
  setOutgoingPaymentTimeRange: () => {},
  transactionRequest: defaultTransactionRequest,
});

export function TransactionsDataProvider({ children }: Props) {
  const { transactionsParams, accountsData, listOfActiveAccounts } = useContext(DataContext);
  const transactionResponse = useTransactionQuery(transactionsParams, { enabled: !!transactionsParams.length });
  const transactionRequest = useRef(defaultTransactionRequest);

  //Edited transactions
  const identifiedTransactions = useMemo(() => {
    if (transactionResponse.isPending) return {};

    try {
      for (let i = 0; i < transactionsParams.length; i++) {
        const accountId = transactionsParams[i].id;
        const transactions = transactionResponse.data[i]?.slice(0, 50) ?? [];

        transactionRequest.current = [
          ...transactionRequest.current,
          ...(!transactionRequest.current.some((item) => item.accountId === accountId)
            ? [
                {
                  accountId: accountId,
                  label: getAccountName(accountsData[accountId]),
                  response: formatResponse({
                    pageNumber: 0,
                    pageCount: 0,
                    pageSize: transactions.length < 50 ? transactions.length : 50,
                    transactions: transactions,
                  }),
                },
              ]
            : []),
        ];
      }

      return transactionsParams.reduce(
        (acc, cur, index) => ({
          ...acc,
          [cur.id]: transactionResponse.data[index].map((transaction) => ({
            ...transaction,
            account: cur.id,
            date: getTransactionDate(transaction),
            identification: getIdentification(transaction),
          })),
        }),
        {} as Record<string, Transaction[]>,
      );
    } catch (error) {
      logError(error);
      return {};
    }
  }, [accountsData, transactionRequest, transactionResponse.data, transactionResponse.isPending, transactionsParams]);

  const flatTransactions = useMemo(() => {
    const transactions = Object.keys(identifiedTransactions).flatMap((id) => identifiedTransactions[id]);
    return transactions.sort((a, b) => b.date.getTime() - a.date.getTime());
  }, [identifiedTransactions]);

  const [incomingPaymentTimeRange, setIncomingPaymentTimeRange] = useState("30");
  const [outgoingPaymentTimeRange, setOutgoingPaymentTimeRange] = useState("30");

  const { transactionsOfActiveAccounts, incomingPaymentCount, outgoingPaymentCount } = useMemo(() => {
    const transactionsOfActiveAccounts: Context["transactionsOfActiveAccounts"] = [];
    const incomingPaymentCount: PaymentCount = {};
    const outgoingPaymentCount: PaymentCount = {};

    if (transactionResponse.isPending) {
      return { transactionsOfActiveAccounts, incomingPaymentCount, outgoingPaymentCount };
    }

    try {
      Object.keys(identifiedTransactions).forEach((account) => {
        if (listOfActiveAccounts.includes(account)) {
          identifiedTransactions[account].forEach((transaction) => {
            transactionsOfActiveAccounts.push(transaction);

            if (
              transaction.creditDebitIndicator === "CRDT" &&
              isAfter(transaction.date, subDays(new Date(), Number(incomingPaymentTimeRange)))
            ) {
              updatePaymentCount(incomingPaymentCount, transaction, true);
            }

            if (
              transaction.creditDebitIndicator === "DBIT" &&
              isAfter(transaction.date, subDays(new Date(), Number(outgoingPaymentTimeRange)))
            ) {
              updatePaymentCount(outgoingPaymentCount, transaction, false);
            }
          });
        }
      });

      transactionsOfActiveAccounts.sort((a, b) => b.date.getTime() - a.date.getTime());

      return { transactionsOfActiveAccounts, incomingPaymentCount, outgoingPaymentCount };
    } catch (error) {
      logError(error);
      return { transactionsOfActiveAccounts, incomingPaymentCount, outgoingPaymentCount };
    }
  }, [
    identifiedTransactions,
    incomingPaymentTimeRange,
    listOfActiveAccounts,
    outgoingPaymentTimeRange,
    transactionResponse,
  ]);

  return (
    <TransactionsDataContext.Provider
      value={{
        transactions: flatTransactions,
        areTransactionsQueriesPending: transactionResponse.isPending,
        areTransactionsQueriesError: transactionResponse.isError,
        transactionsOfActiveAccounts,
        outgoingPaymentCount,
        identifiedTransaction: identifiedTransactions,
        incomingPaymentCount,
        incomingPaymentTimeRange,
        outgoingPaymentTimeRange,
        setIncomingPaymentTimeRange,
        setOutgoingPaymentTimeRange,
        transactionRequest: transactionRequest.current,
      }}
    >
      {children}
    </TransactionsDataContext.Provider>
  );
}

function updatePaymentCount(
  counts: PaymentCount,
  transaction: AccountTransactionsResponse["transactions"][number],
  isIncoming: boolean,
) {
  const counterPart = isIncoming
    ? transaction.entryDetails?.transactionDetails?.relatedParties?.debtor
    : transaction.entryDetails?.transactionDetails?.relatedParties?.creditor;
  const counterPartAccount = isIncoming
    ? transaction.entryDetails?.transactionDetails?.relatedParties?.debtorAccount
    : transaction.entryDetails?.transactionDetails?.relatedParties?.creditorAccount;

  const accountName = counterPartAccount?.name ?? "";
  const subjectName = counterPart?.name ?? "";
  const iban = counterPartAccount?.identification?.iban ?? "";
  const accountNumber = counterPartAccount?.identification?.other?.identification;

  const id = accountName + subjectName + iban || "Ostatní";
  const label = accountName || subjectName || accountNumber || iban || "Ostatní";

  if (!counts[id]) counts[id] = { amount: 0, quantity: 0, label: "" };

  counts[id] = {
    amount: (counts[id].amount += transaction.amount.value),
    quantity: counts[id].quantity + 1,
    label,
  };
}

function getTransactionDate(data: ApiTransaction): Date {
  const bookingDate = data.bookingDate?.date;
  const valueDate = data.valueDate?.date;
  const today = new Date();

  const date = bookingDate ? new Date(bookingDate) : valueDate ? new Date(valueDate as unknown as Date) : today;
  date.setHours(0, 0, 0, 0);

  return date;
}

function getIdentification({ entryDetails }: ApiTransaction): string[] {
  const rawIdentification =
    entryDetails?.transactionDetails?.remittanceInformation?.structured?.creditorReferenceInformation?.reference;

  return rawIdentification?.split(/[/,]/) ?? [];
}
