import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ApiError, PaymentProvidersResponse, validatePaymentProvidersResponse } from "../types.ts";
import { getApiUrl } from "../utils.ts";
import { api } from "../ky.ts";

export const PAYMENT_PROVIDERS_QUERY_KEY = "paymentProviders";

export function usePaymentProvidersQuery(
  options?: Omit<UseQueryOptions<PaymentProvidersResponse, ApiError>, "queryFn" | "queryKey">,
) {
  return useQuery<PaymentProvidersResponse, ApiError>({
    ...options,
    queryKey: [PAYMENT_PROVIDERS_QUERY_KEY],
    queryFn: async () => {
      return validatePaymentProvidersResponse(
        await api.get(getApiUrl("/multibank/status/paymentProviders"), { retry: 0 }).json(),
      );
    },
  });
}
