import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ApiError, TokensResponse, validateTokensResponse } from "../types.ts";
import { getApiUrl } from "../utils.ts";
import { api } from "../ky.ts";

export const TOKENS_QUERY_KEY = "tokens";

export function useTokensQuery(options?: Omit<UseQueryOptions<TokensResponse, ApiError>, "queryFn" | "queryKey">) {
  return useQuery<TokensResponse, ApiError>({
    ...options,
    queryKey: [TOKENS_QUERY_KEY],
    queryFn: async () => {
      return validateTokensResponse(await api.get(getApiUrl("/multibank/users/me/tokens")).json());
    },
  });
}
