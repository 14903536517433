import { MenuDesktop } from "./components/MenuDesktop/MenuDesktop.tsx";
import { MenuMobile } from "./components/MenuMobile/MenuMobile.tsx";
import logo from "../../assets/logo.svg";
import css from "./Header.module.css";
import { UserMenu } from "./components/UserMenu/UserMenu.tsx";
import { ERoutes } from "../../router/routes.ts";
import { useKeycloakUserQuery } from "../../auth/keycloak.ts";
import { useWindowSize } from "@uidotdev/usehooks";
import { useQueryClient } from "@tanstack/react-query";
import { ACCOUNT_LIST_KEY, ACCOUNT_TRANSACTIONS_KEY, TOKENS_QUERY_KEY } from "../../api";
import { NavLink } from "react-router-dom";

export type MenuItem = { text: string; href: ERoutes };
const menuItems: MenuItem[] = [
  { text: "Dashboard", href: ERoutes.Home },
  { text: "Transakce", href: ERoutes.Transactions },
  { text: "Endpointy", href: ERoutes.Requests },
];

export function Header() {
  const size = useWindowSize();
  const isDesktop = Boolean((size.width ?? 0) > 640);

  const client = useQueryClient();
  const invalidate = () => {
    void client.invalidateQueries({ queryKey: [TOKENS_QUERY_KEY] });
    void client.invalidateQueries({ queryKey: [ACCOUNT_LIST_KEY] });
    void client.invalidateQueries({ queryKey: [ACCOUNT_TRANSACTIONS_KEY] });
  };

  const userInfo = useKeycloakUserQuery();

  if (userInfo.isPending) return null;

  const firstName = userInfo.data?.firstName ?? "";
  const fullName = `${userInfo.data?.firstName} ${userInfo.data?.lastName}`;
  const email = userInfo.data?.username ?? "";
  return (
    <header className={css.header}>
      <div className={css.container}>
        <NavLink to={ERoutes.Home}>
          <img
            className={"cursor-pointer"}
            title={"Aktualizovat data"}
            src={logo}
            alt="Finbricks logo"
            width={65.6}
            height={40}
            onClick={invalidate}
          />
        </NavLink>

        {isDesktop && <MenuDesktop items={menuItems} />}
        {!isDesktop && <MenuMobile email={email} fullName={fullName} items={menuItems} />}

        <UserMenu fullName={fullName} email={email} firstName={firstName} />
      </div>
    </header>
  );
}
