import { Navigate, Outlet } from "react-router-dom";
import { ERoutes } from "../router/routes.ts";
import { useAuth } from "../auth/hooks/useAuth.ts";
import { useStatusQuery } from "../api";
import { DataProvider } from "../dataProviders/DataProvider.tsx";
import { PromoCodePage } from "../pages/PromoCodePage/PromoCodePage.tsx";
import { UrlParamsProvider } from "../router/UrlParamsProvider.tsx";
import { Header } from "./Header/Header.tsx";
import { Footer } from "./Footer/Footer.tsx";
import { Container } from "../components/Container/Container.tsx";
import { Loader } from "../components/Loader/Loader.tsx";
import { ScrollToTop } from "../components/ScrollToTop.tsx";
import { TransactionsDataProvider } from "../dataProviders/TransactionsDataProvider.tsx";

export function Layout() {
  const { isAuthenticated, isLoadingUserData } = useAuth();
  const { isPending, data } = useStatusQuery();

  if (!isAuthenticated) return <Navigate to={ERoutes.Login} replace={true} />;

  if (isPending || isLoadingUserData) return <Loader grow={true} message={"Autorizuji"} />;

  return (
    <UrlParamsProvider>
      <ScrollToTop />
      <Header />
      {data ? (
        <DataProvider>
          <TransactionsDataProvider>
            <Container>
              <Outlet />
            </Container>
          </TransactionsDataProvider>
        </DataProvider>
      ) : (
        <PromoCodePage />
      )}
      <Footer />
    </UrlParamsProvider>
  );
}
