import { useContext } from "react";
import { Filter } from "./components/Filter.tsx";
import { DataContext } from "../../dataProviders/DataProvider.tsx";
import { Pagination, Select } from "../../components";
import { InfoMessage } from "./components/InfoMessage.tsx";
import { QueryError } from "../../components/QueryError.tsx";
import { Loader } from "../../components/Loader/Loader.tsx";
import { EmptyState } from "./components/EmptyState.tsx";
import { TransactionsTable } from "./components/TransactionsTable/TransactionsTable.tsx";
import { ItemsPerPage, usePagination } from "../../components/Pagination/usePagination.ts";
import { useFilter } from "./hooks/useFilter.ts";
import { useSort } from "./hooks/useSort.ts";
import css from "./Transactions.module.css";
import { TransactionsDataContext } from "../../dataProviders/TransactionsDataProvider.tsx";

type TableData = {
  account: string;
  date: string;
  direction: string;
  counterParty: string;
  identification: string;
  message: string;
  amount: number;
  currency: string;
};

export type Column = keyof TableData;

export function Transactions() {
  const { accountsData, areAccountQueriesPending, areAccountQueriesError } = useContext(DataContext);
  const { transactions, areTransactionsQueriesPending, areTransactionsQueriesError } =
    useContext(TransactionsDataContext);

  const filter = useFilter(transactions);
  const { filteredTransactions } = filter;

  const sort = useSort(filteredTransactions);

  const pagination = usePagination(sort.sortedTransactions);
  const { currentPage, itemsPerPage, setItemsPerPage } = pagination;

  if (areAccountQueriesError)
    return <QueryError message={"Nepodařilo se načíst informace o Vašich účtech"} grow={true} />;
  if (areAccountQueriesPending) return <Loader grow={true} message={"Načítání informací o bankách"} />;
  if (areTransactionsQueriesError)
    return <QueryError message={"Nepodařilo se načíst informace o Vašich transakcích"} grow={true} />;
  if (areTransactionsQueriesPending) return <Loader grow={true} message={"Načítání informací o transakcích"} />;

  return (
    <div className={"bg-gray-25 grow"}>
      <div className={"app-container mt-14"}>
        <div className={css.selectForTablets}>
          <Select
            disabled={!transactions.length}
            value={String(itemsPerPage)}
            variant={"secondary"}
            options={selectNumberOfResultsTop}
            onChange={(value) => setItemsPerPage(Number(value) as ItemsPerPage)}
          />
          <InfoMessage length={transactions.length} currentPage={currentPage} itemsPerPage={itemsPerPage} />
        </div>

        <Filter filter={filter} isDisabled={!transactions.length} />

        {!pagination.itemsDividedToPages.length && <EmptyState />}

        {pagination.itemsDividedToPages.length > 0 && (
          <TransactionsTable
            transactions={pagination.itemsDividedToPages}
            accountsData={accountsData}
            sortTransactions={sort.sortTransactions}
            sortBy={sort.sortBy}
            sort={sort.sort}
          />
        )}

        <div className={css.mainSelect}>
          <Pagination control={pagination} className={css.pagination} />
          <div className={"flex gap-4 flex-wrap items-center justify-center"}>
            <p className={"typo-text-md-medium"}>Zobrazit</p>
            <Select
              disabled={!transactions.length}
              variant={"secondary"}
              options={selectNumberOfResultsBottom}
              value={String(itemsPerPage)}
              onChange={(value) => setItemsPerPage(Number(value) as ItemsPerPage)}
            />
            <InfoMessage
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              length={sort.sortedTransactions.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const selectNumberOfResultsTop = [
  { value: "10", label: "10 výsledků" },
  { value: "25", label: "25 výsledků" },
  { value: "50", label: "50 výsledků" },
  { value: "100", label: "100 výsledků" },
];

const selectNumberOfResultsBottom = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];
