export const CONFIG = {
  API_URL: validate(import.meta.env.VITE_API_URL, "API_URL"),
  AUTH_URL: validate(import.meta.env.VITE_AUTH_URL, "AUTH_URL"),
  PROMO_CODE_HASH: validate(import.meta.env.VITE_PROMO_CODE_HASH, "PROMO_CODE_HASH"),
};

function validate(value: unknown, name: string): string {
  const isValid = typeof value === "string" && value.length;

  if (!isValid) throw new Error(`ENV variable ${name} must be defined.`);

  return value;
}

export const useApiMock = import.meta.env.VITE_ENABLED_MOCK === "true";
