import Keycloak, { KeycloakProfile } from "keycloak-js";
import { CONFIG } from "../utils/config.ts";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ApiError } from "../api";
import { logError } from "../utils/sentry.ts";

const { AUTH_URL } = CONFIG;

export const keycloak = new Keycloak({
  url: AUTH_URL,
  realm: "multibank",
  clientId: "multibank-openid",
});

export async function keycloakInit() {
  try {
    await keycloak.init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
    });
  } catch (error) {
    logError(error);
  }
}

export const USER_QUERY_KEY = "user";

export function useKeycloakUserQuery(
  options?: Omit<UseQueryOptions<KeycloakProfile, ApiError>, "queryFn" | "queryKey">,
) {
  return useQuery<KeycloakProfile, ApiError>({
    ...options,
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      return await keycloak.loadUserProfile();
    },
  });
}
