import { Root, Trigger, Portal, Content } from "@radix-ui/react-dropdown-menu";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "../../../../components";
import userIcon from "../../../../assets/_base-avatar.svg";
import css from "./UserMenu.module.css";
import { UserInfo } from "../UserInfo/UserInfo.tsx";
import { LogoutButton } from "../LogoutButton/LogoutButton.tsx";
import { DeleteAccount } from "../DeleteAccount/DeleteAccount.tsx";

interface Props {
  firstName: string;
  fullName: string;
  email: string;
}

export function UserMenu({ firstName, fullName, email }: Props) {
  return (
    <div className={css.userMenu}>
      <Root modal={false}>
        <Trigger className={css.trigger} asChild>
          <button className={css.triggerButton}>
            <img src={userIcon} alt="User icon" width={36} height={36} />
            <p className={css.triggerText}>{firstName}</p>
            <FontAwesomeIcon icon={faChevronDown} width={12} />
          </button>
        </Trigger>
        <Portal>
          <Content className={css.dropdown}>
            <UserInfo fullName={fullName} email={email} />
            <LogoutButton />
            <Divider className={css.dropdownDivider} />
            <DeleteAccount />
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
