import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import { AccountTransactionsResponse, ApiError, validateAccountTransactionsResponse } from "../types.ts";
import { getApiUrl } from "../utils.ts";
import { api } from "../ky.ts";
import { SearchParamsOption } from "ky";

const MAX_DEPTH = 50;

/**
 * Some accounts return HTTP status 400 for transactions fetch - in this case, return empty array.
 * We attempted to prevent the fetch for accounts with pispSuitable === false,
 * but it disqualified some accounts that return transactions normally as well.
 * @param paymentProvider
 * @param accountId
 * @param cursor
 */
async function sanitizedFetch(
  paymentProvider: string,
  accountId: string,
  cursor?: string,
): Promise<AccountTransactionsResponse> {
  const searchParams: SearchParamsOption = cursor ? { cursor } : {};
  const url = getApiUrl(`/multibank/users/me/providers/{paymentProvider}/accounts/{accountId}/transactions`, {
    paymentProvider,
    accountId,
  });

  try {
    return validateAccountTransactionsResponse(await api.get(url, { searchParams, retry: 0 }).json());
  } catch (e) {
    return { transactions: [] };
  }
}

async function fetchTransactions(paymentProvider: string, accountId: string, cursor?: string, depth = 0) {
  const response = await sanitizedFetch(paymentProvider, accountId, cursor);

  const nextCursor = response.links?.find(({ rel }) => rel === "nextPage")?.value;

  if (!nextCursor || depth >= MAX_DEPTH) return response;

  const nextResponse = await fetchTransactions(paymentProvider, accountId, nextCursor, depth + 1);
  if (nextResponse) response.transactions.push(...nextResponse.transactions);

  return response;
}

export interface TransactionsParam {
  token: string;
  id: string;
}

export const ACCOUNT_TRANSACTIONS_KEY = "accountTransaction";
export const useTransactionQuery = (
  transactionsParam: TransactionsParam[],
  options?: Omit<UseQueryOptions<AccountTransactionsResponse, ApiError>, "queryFn" | "queryKey">,
) => {
  return useQueries({
    queries: transactionsParam.map((provider) => ({
      queryKey: [ACCOUNT_TRANSACTIONS_KEY, provider],
      queryFn: async () => fetchTransactions(provider.token, provider.id),
      ...options,
    })),
    combine: (result) => ({
      data: result.map((response) => response.data?.transactions ?? []),
      isPending: result.some(({ isPending }) => isPending),
      isError: result.some(({ isError }) => isError),
    }),
  });
};
