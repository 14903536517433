import { useContext, useMemo, useState } from "react";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { LargestPaymentTable } from "./LargestPaymentTable.tsx";
import { LargestPaymentChart } from "./LargestPaymentChart.tsx";
import { secondaryChartColor } from "../../utils/chartsInfo.ts";
import { TransactionsDataContext } from "../../../../dataProviders/TransactionsDataProvider.tsx";

const LIMIT = 5;

export type TransactionType = "amount" | "quantity";

export function LargestIncomingPayment() {
  const { activeCurrency } = useContext(DataContext);
  const { incomingPaymentCount, setIncomingPaymentTimeRange, incomingPaymentTimeRange } =
    useContext(TransactionsDataContext);

  const [dataType, setDataType] = useState<TransactionType>("amount");

  const sortedIncomingKeys = useMemo(() => {
    const keys = Object.keys(incomingPaymentCount).sort((a, b) => {
      return incomingPaymentCount[b][dataType] - incomingPaymentCount[a][dataType];
    });
    return keys.slice(0, keys.length < LIMIT ? keys.length : LIMIT);
  }, [incomingPaymentCount, dataType]);

  return (
    <section className={"grid grid-cols-1 gap-8 lg:grid-cols-12"}>
      <LargestPaymentChart
        dataKeys={sortedIncomingKeys}
        dataType={dataType}
        setDataType={setDataType}
        paymentCount={incomingPaymentCount}
        currency={activeCurrency}
        setTimeRange={setIncomingPaymentTimeRange}
        timeRange={incomingPaymentTimeRange}
        headline={"Protiúčty s nejvíce transakcemi - Příchozí"}
        color={secondaryChartColor}
      />
      <LargestPaymentTable
        dataKeys={sortedIncomingKeys}
        dataType={dataType}
        paymentCount={incomingPaymentCount}
        currency={activeCurrency}
        headline={dataType === "amount" ? "Nejvyšší celkové příjmy" : "Nejvyšší počet transakcí"}
      />
    </section>
  );
}
