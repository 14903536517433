import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function logError(error: unknown) {
  if (!import.meta.env.VITE_SENTRY_DSN) console.error(error);
  if (error instanceof Error) Sentry.captureException(error);
}

export function logValidationError<T>(fn: () => T): T {
  try {
    return fn();
  } catch (error) {
    logError(error);
    throw error;
  }
}
