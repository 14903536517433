import { Select } from "../../../../components";
import { selectDisplay } from "./utils.ts";

interface Props {
  timeDisplay: (typeof selectDisplay)[number]["value"];
  changeTimeDisplay: (value: (typeof selectDisplay)[number]["value"]) => void;
  timeRange: string;
  changeTimeRange: (value: string) => void;
}

export function BalanceDevelopmentSelect({ timeDisplay, changeTimeDisplay, timeRange, changeTimeRange }: Props) {
  return (
    <div className={"flex gap-3 md:justify-end xl:col-start-11 xl:col-span-5"}>
      <Select
        value={timeDisplay}
        variant={"primary"}
        onChange={changeTimeDisplay}
        options={selectDisplay}
        className={"lg:order-2"}
      />
      <Select
        value={timeRange}
        variant={"primary"}
        onChange={changeTimeRange}
        options={selectData}
        className={"lg:order-1"}
      />
    </div>
  );
}

const selectData = [
  { value: "7", label: "7 dní" },
  { value: "30", label: "30 dní" },
  { value: "90", label: "90 dní" },
  { value: "custom", label: "Vlastní" },
];
