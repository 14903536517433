import { useTokensMutation, STATUS_QUERY_KEY } from "../../api";
import { useForm, SubmitHandler } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import sha256 from "sha256";
import { CONFIG } from "../../utils/config.ts";
import { Button, Divider, LinkButton, Input } from "../../components";
import logo from "../../assets/logo.svg";
import css from "./PromoCodePage.module.css";
import { Loader } from "../../components/Loader/Loader.tsx";
import { useToast } from "../../components/Toast/useToast.ts";

type Input = {
  promoCode: string;
};

const { PROMO_CODE_HASH } = CONFIG;

export function PromoCodePage() {
  const { mutateAsync, isPending } = useTokensMutation();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>();

  const { showToast } = useToast();

  const onSubmit: SubmitHandler<Input> = async (data) => {
    try {
      await mutateAsync({ ...data });
      await queryClient.invalidateQueries({ queryKey: [STATUS_QUERY_KEY] });
    } catch (e) {
      showToast({ variant: "attention", title: "Nebylo možné ověřit promo kód", description: "Zkuste to znovu." });
    }
  };

  if (isPending) return <Loader grow={true} message={"Ověřujeme promo kód"} />;

  return (
    <main className={css.main}>
      <div className={css.formSection}>
        <img src={logo} alt="Finbricks logo" width={100} height={61} />

        <h1 className={css.formHeadline}>Finbricks Multibank Demo</h1>
        <p className={css.formText}>Multibank lze použit pouze po zadání promo kódu</p>

        <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={"Promo kód"}
            variant={"high-contrast"}
            placeholder={"Vložte promo kód"}
            required={true}
            containerProps={{ className: "mb-6 w-full max-w-sm" }}
            {...register("promoCode", {
              required: "Toto pole je povinné",
              validate: (value) => {
                if (sha256(value) !== PROMO_CODE_HASH) return "Neplatný promo kód";
                return true;
              },
            })}
            isError={!!errors.promoCode?.message}
            messages={errors.promoCode?.message ? [{ variant: "error", text: errors.promoCode.message }] : undefined}
          />
          <Button variant={"default"} size={"sm"} color={"primary"} type="submit">
            Potvrdit
          </Button>
        </form>
      </div>

      <Divider className={css.divider} />
      <div>
        <h3 className={css.linkHeadline}>Proč používat Finbricks?</h3>
        <p className={css.linkText}>
          Zjistěte sami:{" "}
          <LinkButton variant={"default"} color={"primary"} size={"md"} className={"inline"}>
            Finbricks.com
          </LinkButton>
        </p>
      </div>
    </main>
  );
}
