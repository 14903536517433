import { PropsWithChildren, createContext, useRef, useCallback } from "react";
import { Toast, ToastRef } from "./Toast.tsx";

const noop = () => {};

export const ToastContext = createContext<{ showToast: ToastRef["publish"] }>({ showToast: noop });

export function ToastProvider({ children }: PropsWithChildren) {
  const toastRef = useRef<ToastRef>(null);

  const showToast = useCallback<ToastRef["publish"]>((data) => toastRef.current?.publish(data), []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast ref={toastRef} />
    </ToastContext.Provider>
  );
}
