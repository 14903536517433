import { useCallback, useEffect, useMemo } from "react";
import { useUrlParams } from "../../router/useUrlParams.ts";
import { useLocalStorage } from "@uidotdev/usehooks";
import { getItemsDividedToPages, getPaginationLinks, PAGE_URL_PARAM } from "./utils.ts";

export const itemsPerPageOptions = [10, 25, 50, 100] as const;

export type ItemsPerPage = (typeof itemsPerPageOptions)[number];
export interface PaginationLink {
  href?: string;
  label: number | "...";
}

export function usePagination<T>(items: T[]) {
  const params = useUrlParams();
  const currentPage = useMemo(() => Number(params.get(PAGE_URL_PARAM) ?? "1") - 1, [params]);
  const [itemsPerPage, setItemsPerPage] = useLocalStorage<ItemsPerPage>("@fin_per_page", 10);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const paginationLinks = useMemo(() => getPaginationLinks(currentPage, totalPages), [currentPage, totalPages]);
  const itemsDividedToPages = useMemo(
    () => getItemsDividedToPages(currentPage, itemsPerPage, items),
    [currentPage, items, itemsPerPage],
  );

  const { resetPagination } = usePaginationReset();

  useEffect(() => {
    if (totalPages && currentPage + 1 > totalPages) resetPagination();
  }, [currentPage, resetPagination, totalPages]);

  return {
    paginationLinks,
    itemsDividedToPages,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
  };
}

export function usePaginationReset() {
  const { remove } = useUrlParams();
  const resetPagination = useCallback(() => remove(PAGE_URL_PARAM), [remove]);

  return { resetPagination };
}
