import { useCallback, useState } from "react";
import { Requests } from "./DataProvider.tsx";
import { formatResponse } from "../api/helperFunctions.ts";
import { GetAccountListWithBalanceResponse, PaymentProvidersResponse, TokensResponse } from "../api";

const defaultRequests: Requests = { paymentProviders: "", tokens: "", accounts: [] };

export function useRequests() {
  const [requests, setRequests] = useState(defaultRequests);

  const setTokensRequests = useCallback((tokensData: TokensResponse) => {
    setRequests((prevState) => ({ ...prevState, tokens: formatResponse(tokensData) }));
  }, []);

  const setPaymentProvidersRequests = useCallback((providersData: PaymentProvidersResponse) => {
    setRequests((prevState) => ({ ...prevState, paymentProviders: formatResponse(providersData) }));
  }, []);

  const setAccountsRequests = useCallback(
    (
      accountData: GetAccountListWithBalanceResponse,
      bankProvider: PaymentProvidersResponse["banks"][number],
      providerKey: string,
    ) => {
      setRequests((prevState) => ({
        ...prevState,
        accounts: [
          ...prevState.accounts,
          ...(!prevState.accounts.some((item) => item.paymentProvider === providerKey)
            ? [
                {
                  paymentProvider: providerKey,
                  label: bankProvider.identification.name,
                  response: formatResponse(accountData),
                },
              ]
            : []),
        ],
      }));
    },
    [],
  );

  return {
    requests,
    setTokensRequests,
    setPaymentProvidersRequests,
    setAccountsRequests,
  };
}
