import { useContext, useMemo } from "react";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { getRelativeTransactionValue } from "../../../../utils/getRelativeTransactionValue.ts";
import { isSameDay, isBefore, subDays, format, setHours } from "date-fns";
import { axisClasses, chartsAxisHighlightClasses, LineChart } from "@mui/x-charts";
import { Box } from "@mui/material";
import {
  axisColor,
  tickColor,
  balanceOverviewCurve,
  balanceOverviewHighlight,
  noDataMessage,
} from "../../utils/chartsInfo.ts";
import { ChartTooltip } from "../ChartsTooltip.tsx";
import { formatDay, formatNumber } from "../../../../utils/format.ts";
import { ScrollArea } from "../../../../components";
import { TransactionsDataContext } from "../../../../dataProviders/TransactionsDataProvider.tsx";
import { logError } from "../../../../utils/sentry.ts";

const TODAY = new Date();
const PRIOR_DATE = subDays(TODAY, 30);

export function BalanceOverviewTransactionsChart() {
  const { balanceOfActiveAccounts, activeCurrency } = useContext(DataContext);
  const { transactionsOfActiveAccounts } = useContext(TransactionsDataContext);
  const data = useMemo(() => {
    if (!transactionsOfActiveAccounts.length) return { labelData: [], valueData: [] };

    const valueData: number[] = [];
    const labelData: Date[] = [];

    let balance = balanceOfActiveAccounts;
    try {
      for (let i = 0; i < transactionsOfActiveAccounts.length; i++) {
        const transaction = transactionsOfActiveAccounts[i];
        if (isBefore(transaction.date, PRIOR_DATE)) continue;

        balance -= getRelativeTransactionValue(transaction);
        if (i && isSameDay(transaction.date, labelData[0])) {
          valueData[0] = balance;
          continue;
        }

        valueData.unshift(balance);
        labelData.unshift(setHours(transaction.date, 0));
      }

      if (valueData.length) {
        valueData.shift();
        valueData.push(balanceOfActiveAccounts);
      }
      return { labelData, valueData };
    } catch (error) {
      logError(error);
      return { labelData, valueData };
    }
  }, [balanceOfActiveAccounts, transactionsOfActiveAccounts]);

  return (
    <div>
      <h5 className={"typo-display-xs-semibold mt-4 mb-2"}>Přehled zůstatků za posledních 30 dní</h5>
      <ScrollArea orientation={"horizontal"} color={"background"}>
        <Box sx={{ width: "100%", minWidth: "350px" }}>
          <LineChart
            key={`${data.valueData.length}_${balanceOfActiveAccounts}`}
            height={318}
            series={[
              {
                data: data.valueData,
                showMark: false,
                curve: "linear",
                color: balanceOverviewCurve,
              },
            ]}
            xAxis={[
              {
                data: data.labelData,
                scaleType: "band",
                valueFormatter: (value) => (value ? format(value, "d.M.yy") : ""),
              },
            ]}
            bottomAxis={{ disableTicks: true, disableLine: false }}
            leftAxis={null}
            grid={{ horizontal: true }}
            sx={{
              [`& .${chartsAxisHighlightClasses.root}`]: { stroke: balanceOverviewHighlight },
              [`& .${axisClasses.root}`]: {
                fill: tickColor,
                [`& .${axisClasses.tickLabel}`]: { fill: tickColor },
                [`& .${axisClasses.line}`]: { stroke: axisColor },
              },
            }}
            margin={{ left: 0, right: 0 }}
            slotProps={{ noDataOverlay: { message: noDataMessage } }}
            slots={{
              axisContent: (props) => {
                if (typeof props.dataIndex !== "number" || !data.valueData.length) return null;
                return (
                  <ChartTooltip
                    value={formatNumber(data.valueData[props.dataIndex])}
                    label={data.labelData.length ? formatDay(data.labelData[props.dataIndex]) : "Chybí data"}
                    currency={activeCurrency}
                  />
                );
              },
            }}
          />
        </Box>
      </ScrollArea>
    </div>
  );
}
