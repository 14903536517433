import { useContext, useMemo, useState } from "react";
import { getAccountName, getCounterPartyInfo } from "../../../utils/tableHelpers.tsx";
import { DataContext } from "../../../dataProviders/DataProvider.tsx";
import { Transaction } from "../../../dataProviders/TransactionsDataProvider.tsx";
import { Column } from "../Transactions.tsx";
import { useUrlParams } from "../../../router/useUrlParams.ts";

const SORT_URL_PARAM = "sort";
const SORT_DEFAULT_VALUE = "";

const SORT_BY_URL_PARAM = "sortBy";
const SORT_BY_DEFAULT_VALUE = "";

export function useSort(transactions: Transaction[]) {
  const { accountsData } = useContext(DataContext);
  const params = useUrlParams();

  const [sortBy, setSortBy] = useState<string>(() => params.get(SORT_BY_URL_PARAM) || SORT_BY_DEFAULT_VALUE);
  const [sort, setSort] = useState<string>(() => params.get(SORT_URL_PARAM) || SORT_DEFAULT_VALUE);

  const changeSortBy = (value: typeof sortBy) => {
    params.set(SORT_BY_URL_PARAM, value);
    setSortBy(value);
  };

  const changeSort = (value: typeof sort) => {
    params.set(SORT_URL_PARAM, value);
    setSort(value);
  };

  const sortTransactions = (column: Column) => {
    if (column === sortBy) {
      changeSort(sort === "asc" ? "desc" : "asc");
      return;
    }
    changeSort("asc");
    changeSortBy(column);
  };

  const sortedTransactions: typeof transactions = useMemo(() => {
    if (!sort || !sortBy) return transactions;

    const coef = sort === "desc" ? -1 : 1;

    return [...transactions].sort((a, b) => {
      if (sortBy === "amount") return (a[sortBy].value - b[sortBy].value) * coef;

      if (sortBy === "date") {
        return (a.date.getTime() - b.date.getTime()) * coef;
      }

      if (sortBy === "account") {
        const accountNameA = getAccountName(accountsData[a.account]) ?? "";
        const accountNameB = getAccountName(accountsData[b.account]) ?? "";
        return accountNameA.localeCompare(accountNameB) * coef;
      }

      if (sortBy === "message") {
        const messageA =
          a.entryDetails?.transactionDetails?.additionalRemittanceInformation ??
          a.entryDetails?.transactionDetails?.remittanceInformation?.unstructured ??
          "";
        const messageB =
          b.entryDetails?.transactionDetails?.additionalRemittanceInformation ??
          b.entryDetails?.transactionDetails?.remittanceInformation?.unstructured ??
          "";
        return messageA.localeCompare(messageB) * coef;
      }

      if (sortBy === "counterParty") {
        const partyA = getCounterPartyInfo(a);
        const partyB = getCounterPartyInfo(b);
        const infoA = partyA.iban ?? partyA.name ?? partyA.accountName ?? "";
        const infoB = partyB.iban ?? partyB.name ?? partyB.accountName ?? "";
        return infoA.localeCompare(infoB) * coef;
      }

      if (sortBy === "identification") {
        const idA = a.identification?.[0] ?? "";
        const idB = b.identification?.[0] ?? "";
        return idA.localeCompare(idB) * coef;
      }

      if (sortBy === "direction") {
        const directionA = a.creditDebitIndicator ?? "";
        const directionB = b.creditDebitIndicator ?? "";
        return directionA.localeCompare(directionB) * coef;
      }

      if (sortBy === "currency") {
        return (a.amount[sortBy] ?? "").localeCompare(b.amount[sortBy] ?? "") * coef;
      }

      return 0;
    });
  }, [accountsData, transactions, sort, sortBy]);
  return { sortBy, sort, sortTransactions, sortedTransactions };
}
