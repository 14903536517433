import loading from "../../assets/loading.svg";
import { clsx } from "clsx";
interface Props {
  message: string;
  grow: boolean;
  className?: string;
}

export function Loader({ message, grow, className }: Props) {
  return (
    <div className={clsx("flex flex-col py-12 px-28 items-center justify-center gap-8", grow && "grow", className)}>
      <img className={"animate-spin w-6"} src={loading} alt="Loading icon" />
      <p className={"typo-display-sm-medium text-center text-gray-900"}>{message}</p>
    </div>
  );
}
