import { clsx } from "clsx";

interface Props {
  className?: string;
}
export function EmptyState({ className }: Props) {
  return (
    <div
      className={clsx(
        "flex flex-col py-12 px-28 items-center justify-center min-h-[31.25rem] gap-8 bg-gray-25",
        className,
      )}
    >
      <p className={"typo-display-sm-medium text-center text-gray-900"}>
        Omlouváme se, ale Vašemu vyhledávání neodpovídají žádné výsledky
      </p>
    </div>
  );
}
