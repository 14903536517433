import { format } from "date-fns";

export const formatNumber = (amount: number) => {
  return isNaN(amount) ? "" : new Intl.NumberFormat("cs-CZ", { minimumFractionDigits: 2 }).format(amount);
};

export function formatDay(value: Date): string {
  return format(value, "d.M.yy");
}

export function formatWeek(start: Date, end: Date): string {
  return `${format(start, "d.M.")}–${format(end, "d.M.")}`;
}
