import ky from "ky";
import { keycloak } from "../auth/keycloak.ts";
import { logError } from "../utils/sentry.ts";

export const api = ky.extend({
  hooks: {
    beforeRequest: [
      async (request) => {
        try {
          await keycloak.updateToken(30);
          request.headers.set("Authorization", `Bearer ${keycloak.token}`);
        } catch (e: unknown) {
          logError(e);
          window.location.reload();
        }
      },
    ],
    beforeError: [
      (error) => {
        logError(error);
        return error;
      },
    ],
  },
});
