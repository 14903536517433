import { useContext, useState, useMemo } from "react";
import { differenceInCalendarDays, isSameDay, subDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faChartLine, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Divider, Tabs } from "../../../../components";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import CustomDatePicker from "../../../../components/CustomDatePicker.tsx";
import { BalanceDevelopmentTable } from "./BalanceDevelopmentTable.tsx";
import { BalanceDevelopmentSelect } from "./BalanceDevelopmentSelect.tsx";
import { BalanceDevelopmentChart } from "./BalanceDevelopmentChart.tsx";
import { getRelativeTransactionValue } from "../../../../utils/getRelativeTransactionValue.ts";
import { formatDay } from "../../../../utils/format.ts";
import {
  BalanceDevelopmentData,
  getAccountBalanceKey,
  getAccountBalanceValue,
  getAccountRelativeBalanceKey,
  getAccountRelativeBalanceValue,
  reduceAccounts,
  selectDisplay,
} from "./utils.ts";
import css from "./BalanceDevelopment.module.css";
import { TransactionsDataContext } from "../../../../dataProviders/TransactionsDataProvider.tsx";
import { MAX_TRANSACTIONS_DATE, MIN_TRANSACTIONS_DATE } from "../../../../utils/transactions.ts";
import { logError } from "../../../../utils/sentry.ts";

export type TabData = { value: "detail" | "total"; text: string }[];

const tabData: TabData = [
  { value: "detail", text: "Detailně" },
  { value: "total", text: "Celkem" },
];

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

export function BalanceDevelopment() {
  const { balanceOfActiveAccounts, listOfActiveAccounts, accountsData } = useContext(DataContext);
  const { transactionsOfActiveAccounts } = useContext(TransactionsDataContext);

  const [accountDisplay, setAccountDisplay] = useState<TabData[number]["value"]>("detail");
  const [timeRange, setTimeRange] = useState("30");
  const [timeDisplay, setTimeDisplay] = useState<(typeof selectDisplay)[number]["value"]>("daily");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const customLeftDate = startDate ? subDays(startDate, 1) : MIN_TRANSACTIONS_DATE;
  const customRightDate = endDate || TODAY;

  const changeTimeDisplay = (value: (typeof selectDisplay)[number]["value"]) => {
    setTimeDisplay(value);
  };

  const changeTimeRange = (value: string) => {
    setTimeRange(value);
    setStartDate(null);
    setEndDate(null);
  };

  const PRIOR_DATE = timeRange === "custom" ? customLeftDate : subDays(TODAY, parseInt(timeRange, 10));
  const numberOfDays =
    timeRange === "custom"
      ? differenceInCalendarDays(customRightDate, customLeftDate)
      : differenceInCalendarDays(TODAY, PRIOR_DATE);

  const { days, weeks, transactionsCount } = useMemo<{
    days: BalanceDevelopmentData[];
    weeks: BalanceDevelopmentData[];
    transactionsCount: number;
  }>(() => {
    const accounts = reduceAccounts(listOfActiveAccounts, accountsData);

    const rightDay = timeRange === "custom" ? customRightDate : TODAY;

    const days: BalanceDevelopmentData[] = [
      { balance: balanceOfActiveAccounts, relativeBalance: 0, date: rightDay, label: formatDay(rightDay), ...accounts },
    ];

    let transactionsCount = 0;

    if (!transactionsOfActiveAccounts.length) return { days: [], weeks: [], transactionsCount };

    try {
      for (let i = 0; i < numberOfDays - 1; i++) {
        const day = subDays(timeRange === "custom" ? customRightDate : TODAY, i + 1);
        days.unshift({ balance: 0, relativeBalance: 0, date: day, label: formatDay(day), ...accounts });
      }

      let currentDayIndex = numberOfDays - 1;

      const getCurrentDay = (transactionDate: Date): BalanceDevelopmentData | undefined => {
        const currentDay = days[currentDayIndex];
        if (!currentDay) return;

        if (isSameDay(transactionDate, currentDay.date)) {
          return currentDay;
        }

        if (currentDayIndex === 0) {
          currentDayIndex = numberOfDays - 1;
          return;
        }

        currentDayIndex--;
        return getCurrentDay(transactionDate);
      };

      for (const transaction of transactionsOfActiveAccounts) {
        const currentDay = getCurrentDay(transaction.date);

        if (!currentDay) continue;

        transactionsCount++;

        const relativeValue = getRelativeTransactionValue(transaction);
        currentDay.relativeBalance += relativeValue;
        currentDay[getAccountRelativeBalanceKey(transaction.account)] =
          getAccountRelativeBalanceValue(transaction.account, currentDay) + relativeValue;
      }

      for (let i = days.length - 2; i >= 0; i--) {
        days[i].balance = days[i + 1].balance + -days[i + 1].relativeBalance;

        for (const key of listOfActiveAccounts) {
          days[i][getAccountBalanceKey(key)] =
            getAccountBalanceValue(key, days[i + 1]) + -getAccountRelativeBalanceValue(key, days[i + 1]);
        }
      }

      const weeks: BalanceDevelopmentData[] = [];
      let weekdayIterator = 0;
      for (let i = days.length - 1; i >= 0; i--) {
        const { date, balance, relativeBalance } = days[i];
        const isFirstWeekDay = !weekdayIterator;
        const isLastWeekDay = weekdayIterator === 6;
        const isLastDay = !i;

        if (isFirstWeekDay) {
          const newWeek: BalanceDevelopmentData = { date, balance, relativeBalance, label: formatDay(date) };
          for (const accountKey of listOfActiveAccounts) {
            newWeek[getAccountBalanceKey(accountKey)] = getAccountBalanceValue(accountKey, days[i]);
            newWeek[getAccountRelativeBalanceKey(accountKey)] = getAccountRelativeBalanceValue(accountKey, days[i]);
          }

          weeks.unshift(newWeek);
          weekdayIterator++;
          continue;
        }

        weeks[0].relativeBalance += relativeBalance;
        for (const accountKey of listOfActiveAccounts) {
          weeks[0][getAccountRelativeBalanceKey(accountKey)] =
            getAccountRelativeBalanceValue(accountKey, weeks[0]) + getAccountRelativeBalanceValue(accountKey, days[i]);
        }

        if (isLastWeekDay || isLastDay) weeks[0].label = `${formatDay(date)}–${weeks[0].label}`;
        weekdayIterator = isLastWeekDay ? 0 : weekdayIterator + 1;
      }

      return { days, weeks, transactionsCount };
    } catch (e) {
      logError(e);
      return { days: [], weeks: [], transactionsCount };
    }
  }, [
    accountsData,
    balanceOfActiveAccounts,
    customRightDate,
    listOfActiveAccounts,
    numberOfDays,
    timeRange,
    transactionsOfActiveAccounts,
  ]);

  const dataset = transactionsCount ? (timeDisplay === "daily" ? days : weeks) : [];

  const showTable = !!transactionsCount && !!listOfActiveAccounts.length;

  return (
    <div className={css.widgetCard}>
      <div className={"flex gap-4"}>
        <FontAwesomeIcon className={css.icon} icon={faChartLine} />
        <h3 className={css.headline}>Vývoj zůstatku v čase</h3>
      </div>
      <div className={"grid gap-6 grid-cols-1 grid-rows-1 md:grid-cols-2 xl:grid-rows-1 xl:grid-cols-15 xl:gap-2"}>
        <div className={"flex gap-3 xl:col-span-4"}>
          {tabData.map((tab) => (
            <Tabs
              key={tab.value}
              startIcon={<FontAwesomeIcon className={"w-1.5 mt-0.5"} icon={faCircle} />}
              variant={"box"}
              onClick={() => setAccountDisplay(tab.value)}
              isActive={accountDisplay === tab.value}
            >
              {tab.text}
            </Tabs>
          ))}
        </div>

        {timeRange === "custom" && (
          <div className={css.datePickers}>
            <CustomDatePicker
              className={"md:w-full"}
              minDate={MIN_TRANSACTIONS_DATE}
              maxDate={MAX_TRANSACTIONS_DATE}
              value={startDate}
              onChange={setStartDate}
              onClear={() => setStartDate(null)}
              text={"Datum od"}
            />
            <FontAwesomeIcon className={"w-4 h-4 text-gray-700"} icon={faMinus} />
            <CustomDatePicker
              className={"md:w-full"}
              minDate={MIN_TRANSACTIONS_DATE}
              maxDate={MAX_TRANSACTIONS_DATE}
              value={endDate}
              onChange={setEndDate}
              onClear={() => setEndDate(null)}
              text={"Datum do"}
            />
          </div>
        )}
        <BalanceDevelopmentSelect
          timeRange={timeRange}
          changeTimeRange={changeTimeRange}
          timeDisplay={timeDisplay}
          changeTimeDisplay={changeTimeDisplay}
        />
      </div>

      <Divider />
      <BalanceDevelopmentChart accountDisplay={accountDisplay} dataset={dataset} />
      {showTable ? <BalanceDevelopmentTable accountDisplay={accountDisplay} dataset={dataset} /> : null}
    </div>
  );
}
