import { Divider, Flag } from "../../../../components";
import { getNameFromCountryCode } from "../../../../utils/getLocale.ts";

export function CountryLegend({ countryCode, className }: { countryCode: string; className?: string }) {
  return (
    <div className={className}>
      <div className={"flex gap-2 mb-2"}>
        <Flag className={"w-[1rem] h-[1rem]"} code={countryCode} />
        <p className={"typo-text-sm-regular text-gray-500 whitespace-nowrap"}>{getNameFromCountryCode(countryCode)}</p>
      </div>
      <Divider color={"dark"} />
    </div>
  );
}
