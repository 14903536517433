import { AccountsData } from "../../../../dataProviders/DataProvider.tsx";

type AccountData = Record<string, number>;

export type BalanceDevelopmentData = {
  balance: number;
  relativeBalance: number;
  date: Date;
  label: string;
  [key: string]: string | number | Date;
};

export const selectDisplay: { value: "daily" | "weekly"; label: string }[] = [
  { value: "daily", label: "Denní přehled" },
  { value: "weekly", label: "Týdenní přehled" },
];

export const getAccountBalanceKey = (id: string) => `balance_${id}`;
export const getAccountBalanceValue = (id: string, data: BalanceDevelopmentData) => {
  return data[getAccountBalanceKey(id)] as number;
};

export const getAccountRelativeBalanceKey = (id: string) => `relativeBalance_${id}`;
export const getAccountRelativeBalanceValue = (id: string, data: BalanceDevelopmentData) => {
  return data[getAccountRelativeBalanceKey(id)] as number;
};

export function reduceAccounts(activeAccounts: string[], accountsData: AccountsData): AccountData {
  return activeAccounts.reduce(
    (acc, cur) => ({
      ...acc,
      [getAccountBalanceKey(cur)]: accountsData[cur].balance,
      [getAccountRelativeBalanceKey(cur)]: 0,
    }),
    {} as AccountData,
  );
}
