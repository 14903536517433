type LegendItem = { label: string; color: string };
interface Props {
  legendItems: LegendItem[];
}

export function ChartLegend({ legendItems }: Props) {
  return (
    <div className={"flex flex-wrap gap-6"}>
      {legendItems.map((item) => (
        <div key={`${item.label}_${item.color}`} className={"flex  flex-wrap gap-2"}>
          <div className={"w-8 h-4 rounded bg-secondary-500 "} style={{ backgroundColor: item.color }}></div>
          <p className={"typo-text-md-medium"}>{item.label}</p>
        </div>
      ))}
    </div>
  );
}
