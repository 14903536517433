import { Input, Select } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import CustomDatePicker from "../../../components/CustomDatePicker.tsx";
import { useContext, useMemo } from "react";
import { DataContext } from "../../../dataProviders/DataProvider.tsx";
import { useFilter } from "../hooks/useFilter.ts";
import css from "../Transactions.module.css";
import { SelectOption } from "../../../components/Select/Select.tsx";
import { getAccountName } from "../../../utils/tableHelpers.tsx";
import { MAX_TRANSACTIONS_DATE, MIN_TRANSACTIONS_DATE } from "../../../utils/transactions.ts";

interface Props {
  filter: ReturnType<typeof useFilter>;
  isDisabled: boolean;
}

export function Filter({ filter, isDisabled }: Props) {
  const { accountsData } = useContext(DataContext);

  const selectAccount = useMemo(() => {
    const select: SelectOption<string>[] = [{ label: "Všechny", value: "all" }];
    for (const key in accountsData) {
      const label = getAccountName(accountsData[key]);
      select.push({
        label: label,
        value: key,
        slot: (
          <img
            className={"w-6 h-6 object-contain max-w-none shrink-0"}
            src={accountsData[key].bank.identification.logoUrl}
            alt={accountsData[key].bank.identification.name}
          />
        ),
      });
    }
    return select;
  }, [accountsData]);

  return (
    <>
      <Input
        value={filter.search}
        onChange={(e) => filter.setSearch(e.target.value)}
        disabled={isDisabled}
        placeholder={"Zadejte IBAN, jméno, název účtu..."}
        containerProps={{ className: "max-w-md" }}
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
      />

      <div className={css.filters}>
        <div className={css.selectWrapper}>
          <Select
            disabled={isDisabled}
            value={filter.filterAccounts}
            variant={"secondary"}
            label={"Účty"}
            size={"lg"}
            onChange={filter.changeFilterAccounts}
            options={selectAccount}
            className={"flex-1"}
          />
          <Select
            disabled={isDisabled}
            value={filter.filterDirection}
            variant={"secondary"}
            label={"Směr transakce"}
            size={"lg"}
            onChange={filter.changeFilterDirection}
            options={selectDirection}
            className={"flex-1"}
          />
          <Select
            disabled={isDisabled}
            value={filter.filterStatus}
            variant={"secondary"}
            label={"Stav transakce"}
            size={"lg"}
            onChange={filter.changeFilterStatus}
            options={selectStatus}
            className={"flex-1"}
          />
        </div>
        <div className={css.datePickerWrapper}>
          <CustomDatePicker
            value={filter.startDate}
            text={"Vyberte období"}
            label={"Datum od"}
            minDate={MIN_TRANSACTIONS_DATE}
            maxDate={MAX_TRANSACTIONS_DATE}
            onChange={filter.changeFilterStart}
            onClear={() => filter.changeFilterStart(null)}
            size={"lg"}
            className={"flex-1"}
          />
          <CustomDatePicker
            value={filter.endDate}
            text={"Vyberte období"}
            label={"Datum do"}
            minDate={MIN_TRANSACTIONS_DATE}
            maxDate={MAX_TRANSACTIONS_DATE}
            onChange={filter.changeFilterEnd}
            onClear={() => filter.changeFilterEnd(null)}
            size={"lg"}
            className={"flex-1"}
          />
        </div>
      </div>
    </>
  );
}

const selectDirection = [
  { value: "all", label: "Všechny" },
  { value: "DBIT", label: "Odchozí" },
  { value: "CRDT", label: "Příchozí" },
];

const selectStatus = [
  { value: "all", label: "Všechny" },
  { value: "BOOK", label: "Zaúčtováno" },
  { value: "PDNG", label: "Blokováno" },
];
