import Logo from "../../assets/logo.svg";
import css from "./Footer.module.css";

export function Footer() {
  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <p className={css.mainText}>
          Tato webová stránka slouží výhradně pro představení a vyzkoušení našich služeb. V případě jakýchkoli dotazů či
          nejasností se na nás neváhejte obrátit na{" "}
          <a href={`mailto:support@finbricks.com`} className={css.link}>
            support@finbricks.com
          </a>
        </p>
        <div className={css.finbricksSection}>
          <img className={css.logo} src={Logo} alt="Finbricks Logo" width={66} height={40} />
          <p className={css.text}>
            Driven by <span className={css.textHighlight}>Finbricks</span>
          </p>
        </div>
      </div>
    </footer>
  );
}
