import { TokensResponse, useTokensQuery } from "../api";
import { useMemo } from "react";

export function useTokens(onFetch: (data: TokensResponse) => void) {
  const tokensResponse = useTokensQuery();
  const tokens = useMemo(() => {
    if (!tokensResponse.data) return [];
    onFetch(tokensResponse.data);

    return tokensResponse.data.map((token) => token.provider);
  }, [onFetch, tokensResponse.data]);

  const isTokenResponseError = tokensResponse.isError;
  const isTokensResponsePending = tokensResponse.isPending;

  return { tokens, isTokenResponseError, isTokensResponsePending };
}
