import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import bash from "highlight.js/lib/languages/bash";
import "highlight.js/styles/stackoverflow-light.css";
import { useMemo, useState } from "react";
import { Card, LinkButton, ScrollArea, Select } from "../../../components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import css from "./RequestCard.module.css";
import { clsx } from "clsx";

hljs.registerLanguage("bash", bash);
hljs.registerLanguage("json", json);

type Props = {
  title: string;
  description: string;
  link: string;
  apiRequest: string;
  apiResponse: string;
  select?: { value: string; label: string }[];
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
};

export function RequestCard({
  title,
  description,
  link,
  apiRequest,
  apiResponse,
  select,
  value,
  onChange,
  label,
}: Props) {
  const requestHtml = useMemo(() => hljs.highlight(apiRequest, { language: "bash" }), [apiRequest]);
  const responseHtml = useMemo(() => hljs.highlight(apiResponse, { language: "json" }), [apiResponse]);

  const [isRequestOpened, setIsRequestOpened] = useState(false);
  const [isResponseOpened, setIsResponseOpened] = useState(false);

  const openRequest = () => {
    setIsRequestOpened(!isRequestOpened);
  };

  const openResponse = () => {
    setIsResponseOpened(!isResponseOpened);
  };

  return (
    <Card variant={"light"} size={"md"}>
      <Link to={link} target="_blank" className={css.link}>
        Dokumentace
        <FontAwesomeIcon className={css.icon} icon={faArrowUpRightFromSquare} />
      </Link>
      <h4 className={"typo-display-sm-semibold mb-4 text-gray-900"}>{title}</h4>
      <p className={"typo-text-md-regular mb-8"}>{description}</p>
      {select && onChange && (
        <Select
          className={"w-full max-w-[25rem] mb-8"}
          value={value ?? ""}
          variant={"tertiary"}
          onChange={onChange}
          options={select}
          label={label}
          size={"lg"}
        />
      )}

      <LinkButton
        className={"w-full justify-between mb-4"}
        variant={"default"}
        color={"primary"}
        size={"lg"}
        endIcon={<FontAwesomeIcon icon={isRequestOpened ? faChevronUp : faChevronDown} />}
        onClick={openRequest}
      >
        API Request
      </LinkButton>
      <ScrollArea orientation={"horizontal"}>
        <div className={clsx(isRequestOpened ? "rounded mb-4" : "hidden")}>
          <pre className={"rounded overflow-hidden bg-gray-100 p-4 text-sm"}>
            <code dangerouslySetInnerHTML={{ __html: requestHtml.value }} />
          </pre>
        </div>
      </ScrollArea>

      <LinkButton
        className={"w-full justify-between mb-6 mt-8"}
        variant={"default"}
        color={"primary"}
        size={"lg"}
        endIcon={<FontAwesomeIcon icon={isResponseOpened ? faChevronUp : faChevronDown} />}
        onClick={openResponse}
      >
        API Response
      </LinkButton>
      <div className={clsx(isResponseOpened ? "w-full rounded mb-4" : "hidden")}>
        <ScrollArea orientation={"horizontal"}>
          <ScrollArea className={"max-h-[600px]"} orientation={"vertical"}>
            <pre className={"rounded overflow-hidden bg-gray-100 p-4 text-sm"}>
              <code
                dangerouslySetInnerHTML={{
                  __html: apiResponse ? responseHtml.value : "Nemáme data. Prosíme, připojte banku.",
                }}
              />
            </pre>
          </ScrollArea>
        </ScrollArea>
      </div>
    </Card>
  );
}
