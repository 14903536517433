import { ReactNode, forwardRef, useImperativeHandle, useState } from "react";
import { Root, Provider, Title, Description, Close, Viewport } from "@radix-ui/react-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleInfo, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../IconButton/IconButton.tsx";
import clsx from "clsx";
import css from "./Toast.module.css";

function genId(): string {
  return `${new Date().getTime()}_${Math.random() * 999}`;
}

type Variant = "success" | "attention" | "neutral";

interface ToastData {
  id: string;
  variant: Variant;
  title: string;
  description: string;
}

export interface ToastRef {
  publish: (data: Omit<ToastData, "id">) => void;
}

export const Toast = forwardRef<ToastRef>(function Toast(_, ref) {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  const push: ToastRef["publish"] = (newToast) => {
    setToasts((prevState) => [...prevState, { ...newToast, id: genId() }]);
  };

  const remove = (id: string) => setToasts((prevState) => prevState.filter((toast) => toast.id !== id));

  useImperativeHandle(ref, () => ({ publish: push }), []);

  return (
    <Provider swipeDirection={"right"} duration={5000}>
      {toasts.map(({ id, variant, title, description }) => (
        <Root key={id} className={clsx(css.root, variantMap[variant])} onOpenChange={(open) => !open && remove(id)}>
          {iconMap[variant]}
          <div>
            <Title className={clsx(css.title, variantTitleMap[variant])}>{title}</Title>
            <Description className={css.description}>{description}</Description>
          </div>

          <span>
            <Close asChild>
              <IconButton
                className={"absolute top-2.5 right-2.5"}
                variant={"default"}
                color={"gray"}
                size={"md"}
                icon={<FontAwesomeIcon icon={faXmark} />}
              />
            </Close>
          </span>
        </Root>
      ))}

      <Viewport className="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-6 w-[388px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
    </Provider>
  );
});

const variantMap: Record<Variant, string> = {
  success: css.success,
  attention: css.attention,
  neutral: css.neutral,
};

const variantTitleMap: Record<Variant, string> = {
  success: css.titleSuccess,
  attention: css.titleAttention,
  neutral: css.titleNeutral,
};

const iconMap: Record<Variant, ReactNode> = {
  success: (
    <div className={"p-2.5 rounded-xl bg-success-100 flex flex-col items-center justify-center"}>
      <FontAwesomeIcon className="w-6 h-6 text-success-700 shrink-0" icon={faCircleCheck} />
    </div>
  ),
  neutral: (
    <div className={"p-2.5 rounded-xl bg-brand-100 flex flex-col items-center justify-center"}>
      <FontAwesomeIcon className="w-6 h-6 text-brand-700 shrink-0" icon={faCircleInfo} />
    </div>
  ),
  attention: (
    <div className={"p-2.5 rounded-xl bg-error-100 flex flex-col items-center justify-center"}>
      <FontAwesomeIcon className="w-6 h-6 text-error-700 shrink-0" icon={faTriangleExclamation} />
    </div>
  ),
};
