import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";
import css from "./Tabs.module.css";
import { clsx } from "clsx";

type Variant = "box" | "text";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant: Variant;
  isActive: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const Tabs = forwardRef<HTMLButtonElement, Props>(function Tabs(
  { variant, startIcon, endIcon, children, className, isActive, ...props },
  ref,
) {
  return (
    <button
      className={clsx(variantMap[variant], className, isActive && activeVariantMap[variant])}
      ref={ref}
      {...props}
    >
      {startIcon && <div className="w-5 h-5 shrink-0">{startIcon}</div>}
      {children}
      {endIcon && <div className="w-5 h-5 shrink-0">{endIcon}</div>}
    </button>
  );
});

const variantMap: Record<Variant, string> = {
  box: css.box,
  text: css.text,
};

const activeVariantMap: Record<Variant, string> = {
  box: css.boxActive,
  text: css.textActive,
};
