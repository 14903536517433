import { getEndIndex, getStartIndex } from "../../../components/Pagination/utils.ts";

interface Props {
  currentPage: number;
  itemsPerPage: number;
  length: number;
}

export function InfoMessage({ currentPage, itemsPerPage, length }: Props) {
  return (
    <p className={"typo-text-sm-regular"}>
      Zobrazuji {getStartIndex(currentPage, itemsPerPage)} až{" "}
      {length < itemsPerPage ? length : getEndIndex(currentPage, itemsPerPage)} z celkem {length} záznamů
    </p>
  );
}
