import { Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthorizeProviderMutation } from "../../../../api/hooks/useAuthorizeProviderMutation.ts";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { Card, Modal, Tabs, Flag, ScrollArea } from "../../../../components";
import { getNameFromCountryCode } from "../../../../utils/getLocale.ts";
import { PaymentProvidersResponse } from "../../../../api";
import css from "./AddBankModal.module.css";
import { clsx } from "clsx";
import { DialogProps } from "@radix-ui/react-dialog";
import { useToast } from "../../../../components/Toast/useToast.ts";
import { logError } from "../../../../utils/sentry.ts";
import { sortCountries } from "../../utils/sortCountries.ts";

interface Props extends Pick<DialogProps, "open" | "onOpenChange"> {
  open?: boolean;
  onOpenChange?: Dispatch<SetStateAction<boolean>>;
  trigger?: ReactNode;
}

type StructuredProviders = Record<string, PaymentProvidersResponse["banks"]>;

export function AddBankModal({ open, onOpenChange, trigger }: Props) {
  const { paymentProviders } = useContext(DataContext);

  const { showToast } = useToast();

  const authorizeMutation = useAuthorizeProviderMutation();
  const authorizeBankProvider = async (paymentProvider: string) => {
    try {
      const { redirectUrl } = await authorizeMutation.mutateAsync({
        token: paymentProvider,
        redirectUrl: window.location.protocol + "//" + window.location.host + window.location.pathname,
      });
      window.location.href = redirectUrl;
    } catch (e) {
      showToast({ variant: "attention", title: "Připojení banky se nezdařilo", description: "Zkuste to znovu." });
    }
  };

  const { structuredProviders, countryKeys } = useMemo(() => {
    const structuredProviders: StructuredProviders = {};
    try {
      for (let i = 0; i < paymentProviders.length; i++) {
        const country = paymentProviders[i].identification.countryCode;
        if (!structuredProviders[country]) structuredProviders[country] = [];
        structuredProviders[country] = [...structuredProviders[country], paymentProviders[i]];
      }
      const countryKeys = Object.keys(structuredProviders);

      const sortedCountries = sortCountries(countryKeys);

      return { structuredProviders, countryKeys: sortedCountries };
    } catch (error) {
      logError(error);
      return { structuredProviders, countryKeys: [] };
    }
  }, [paymentProviders]);

  const [activeCountry, setActiveCountry] = useState(Object.keys(structuredProviders)[0]);

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      trigger={trigger}
      title={"Výběr banky pro synchronizaci"}
      className={"min-h-[32.1rem]"}
      icon={
        <FontAwesomeIcon
          className={css.icon}
          icon={faBuildingColumns}
          width={"22px"}
          height={"22px"}
          color={"#3518ec"}
        />
      }
    >
      <ScrollArea orientation={"horizontal"}>
        <div className={clsx(css.boxContainer, "pb-2")}>
          {countryKeys.map((key, index) => (
            <Tabs
              onClick={() => setActiveCountry(key)}
              key={key + String(index)}
              isActive={activeCountry === key}
              variant={"box"}
              startIcon={<Flag code={key} />}
            >
              {getNameFromCountryCode(key)}
            </Tabs>
          ))}
        </div>
      </ScrollArea>

      <ScrollArea orientation={"vertical"} className={"max-h-[350px] mt-6"}>
        <div className={"flex gap-3 flex-wrap"}>
          {structuredProviders[activeCountry].map(({ identification }) => (
            <button
              key={identification.paymentProvider}
              title={identification.name}
              onClick={() => authorizeBankProvider(identification.paymentProvider)}
              className={"block"}
            >
              <Card key={identification.paymentProvider} variant={"light"} size={"sm"}>
                <img src={identification.logoUrl} alt={identification.name} className={"w-28 h-16 object-contain"} />
              </Card>
            </button>
          ))}
        </div>
      </ScrollArea>
    </Modal>
  );
}
