import { useContext } from "react";
import clsx from "clsx";
import { DataContext, StructuredData } from "../../../../dataProviders/DataProvider.tsx";
import { CountryLegend } from "../CountryLegend/CountryLegend.tsx";
import css from "./BalanceOverviewLegend.module.css";
import { Checkbox, ScrollArea } from "../../../../components";
import { formatNumber } from "../../../../utils/format.ts";
import { Tooltip } from "../../../../components";
import { getAccountName } from "../../../../utils/tableHelpers.tsx";
import { sortCountries } from "../../utils/sortCountries.ts";

type Props = {
  structuredData: StructuredData;
  currency: string;
};

export function BalanceOverviewLegend({ structuredData, currency }: Props) {
  const {
    toggleActiveBank,
    activeBanks,
    activeAccounts,
    toggleActiveAccount,
    selectedCountries,
    balanceOfActiveAccounts,
  } = useContext(DataContext);

  const sortedSelectedCountries = sortCountries(selectedCountries);

  return (
    <form className={"w-full"}>
      {sortedSelectedCountries.map((country: string) => {
        const providers = structuredData[country][currency]?.providers;
        if (!providers) return null;
        return (
          <div key={country} className={"overflow-hidden"}>
            <CountryLegend countryCode={country} className={"mb-6"} />
            {Object.keys(providers).map((provider) => {
              const accounts = providers[provider].accounts;
              const isBankChecked = activeBanks.includes(provider);
              return (
                <ScrollArea key={provider} orientation={"horizontal"}>
                  <table className={"mb-6 w-full border-separate border-spacing-x-0 border-spacing-y-2.5"}>
                    <tbody>
                      <tr>
                        <td>
                          <ColorId color={providers[provider].color} />
                        </td>
                        <td colSpan={3} className={css.bankName}>
                          {providers[provider].info.identification.name}
                        </td>
                        <td align={"right"}>
                          <Tooltip
                            side={"left"}
                            trigger={
                              <div className={"w-5 h-full flex items-center"}>
                                {" "}
                                <Checkbox
                                  size={"md"}
                                  value={provider}
                                  name={provider}
                                  checked={isBankChecked}
                                  onCheckedChange={() => toggleActiveBank(provider)}
                                />
                              </div>
                            }
                            text={isBankChecked ? "Skrýt banku" : "Zobrazit banku"}
                          />
                        </td>
                      </tr>
                      {Object.keys(accounts).map((account) => {
                        const isAccountChecked = activeAccounts.includes(account);
                        return (
                          <tr className={"p-2.5"} key={account}>
                            <td>
                              <ColorId color={accounts[account].color} />
                            </td>
                            <td className={"pl-4"}>
                              <p className={css.accountName}>{getAccountName(accounts[account])}</p>
                              <p className={css.accountNumber}>
                                {accounts[account].identification?.iban ?? "Iban účtu není dostupný"}
                              </p>
                            </td>

                            <td className={css.currency}>{accounts[account].currency}</td>
                            <td align={"right"} className={css.accountBalance}>
                              {formatNumber(accounts[account].balance)}
                            </td>
                            <td align={"right"} className={"pl-4"}>
                              <Tooltip
                                side={"left"}
                                trigger={
                                  <div className={"w-5 h-full flex items-center"}>
                                    <Checkbox
                                      value={account}
                                      name={account}
                                      size={"md"}
                                      checked={isAccountChecked}
                                      onCheckedChange={() => toggleActiveAccount(account)}
                                      isDisabled={!activeBanks.includes(provider)}
                                    />
                                  </div>
                                }
                                text={isAccountChecked ? "Skrýt účet" : "Zobrazit účet"}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </ScrollArea>
              );
            })}
          </div>
        );
      })}
      <div className={clsx(css.totalBalance, "flex justify-end gap-2")}>
        <span className={css.totalBalance}>{formatNumber(balanceOfActiveAccounts)}</span>
        <span>{currency}</span>
      </div>
    </form>
  );
}

function ColorId({ color }: { color: string }) {
  return <span className={clsx(css.colorId)} style={{ backgroundColor: color }}></span>;
}
