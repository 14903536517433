import { useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Close } from "@radix-ui/react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan as faSolidTrashCan, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useRevokeProviderMutation } from "../../../../api/hooks/useRevokeProviderMutation.ts";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { TOKENS_QUERY_KEY } from "../../../../api";
import { Button, Card, Modal } from "../../../../components";
import { formatNumber } from "../../../../utils/format.ts";
import css from "./BankCard.module.css";
import { Tooltip } from "../../../../components";
import { useToast } from "../../../../components/Toast/useToast.ts";

interface Props {
  provider: string;
  logo: string;
  bankName: string;
  balance: number;
  currency: string;
  isLast: boolean;
}
export function BankCard({ logo, bankName, balance, currency, provider, isLast }: Props) {
  const revokeMutation = useRevokeProviderMutation();
  const queryClient = useQueryClient();
  const { storage, accountsData, setActiveCountry, setActiveCurrency, currenciesSelect } = useContext(DataContext);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const { showToast } = useToast();

  const revokeBankProvider = async (token: string) => {
    try {
      storage?.deleteRemovedBank(token, provider, accountsData);
      await revokeMutation.mutateAsync(token);

      if (isLast) {
        setActiveCountry("all");
        setActiveCurrency(currenciesSelect.filter(({ value }) => value !== currency)?.[0]?.value ?? "");
      }

      await queryClient.invalidateQueries({ queryKey: [TOKENS_QUERY_KEY] });
      showToast({ variant: "success", title: "Banka byla odpojena", description: "Odpojení banky bylo úspěšné." });
    } catch (e) {
      showToast({ variant: "attention", title: "Odpojení banky se nezdařilo", description: "Zkuste to znovu." });
    }
  };

  return (
    <Card variant={"medium"} size={"sm"} className={"w-[12.8rem] group"}>
      <div className={"flex justify-between items-start"}>
        <div className={"h-12 flex items-center"}>
          <img src={logo} alt={`${bankName} icon`} className={"h-10 max-w-32 object-contain"} />
        </div>
        <Tooltip
          trigger={
            <button className={"text-gray-100 group-hover:text-gray-600"} onClick={() => setIsRemoveModalOpen(true)}>
              <FontAwesomeIcon className={"w-5 h-5"} icon={faTrashCan} />
            </button>
          }
          text={"Odpojit banku"}
        />

        <Modal
          title={"Odpojit banku"}
          icon={
            <FontAwesomeIcon
              className={css.icon}
              icon={faSolidTrashCan}
              width={"22px"}
              height={"22px"}
              color={"#d92d20"}
            />
          }
          open={isRemoveModalOpen}
          onOpenChange={setIsRemoveModalOpen}
        >
          <p className={css.modalText}>Opravdu chcete odpojit {bankName}.</p>
          <p className={css.modalText}>V budouncu si ji můžete opět kdykoliv připojit.</p>

          <div className={css.buttonGroup}>
            <Close asChild>
              <Button variant={"default"} color={"gray"} size={"md"}>
                Zavřít
              </Button>
            </Close>

            <Button
              variant={"destructive"}
              color={"primary"}
              size={"md"}
              endIcon={<FontAwesomeIcon icon={faSolidTrashCan} />}
              onClick={() => {
                void revokeBankProvider(provider);
              }}
            >
              Odpojit banku
            </Button>
          </div>
        </Modal>
      </div>

      <div className={"mt-3"}>
        <p className={"typo-text-sm-regular mb-1.5"}>Disponibilní zůstatek:</p>
        <div className={"flex justify-between typo-text-lg-bold"}>
          <span>{formatNumber(balance)}</span>
          <span>{currency}</span>
        </div>
      </div>
    </Card>
  );
}
