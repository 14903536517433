import { useMutation } from "@tanstack/react-query";
import {
  ApiError,
  ProviderAuthorizeQuery,
  ProviderAuthorizeResponse,
  validateProviderAuthorizeResponse,
} from "../types.ts";
import { api } from "../ky.ts";
import { getApiUrl } from "../utils.ts";

export function useAuthorizeProviderMutation() {
  return useMutation<ProviderAuthorizeResponse, ApiError, { token: string; redirectUrl: string }>({
    mutationFn: async ({ token, redirectUrl }) => {
      const searchParams: ProviderAuthorizeQuery = { redirectUrl };

      return validateProviderAuthorizeResponse(
        await api
          .post(getApiUrl("/multibank/users/me/providers/{paymentProvider}/authorize", { paymentProvider: token }), {
            searchParams,
          })
          .json(),
      );
    },
  });
}
