import { PaginationLink } from "./usePagination.ts";

export const PAGE_URL_PARAM = "page";

export function getPageParams(page: number) {
  const params = new URLSearchParams(window.location.search);
  params.set(PAGE_URL_PARAM, page.toString(10));
  return `?${params.toString()}`;
}

export function getPaginationLinks(currentPage: number, totalPages: number): PaginationLink[] {
  if (totalPages <= 7)
    return Array.from({ length: totalPages }, (_v, i) => {
      return { href: getPageParams(i + 1), label: i + 1 };
    });
  if (currentPage <= 3)
    return [
      { href: getPageParams(1), label: 1 },
      { href: getPageParams(2), label: 2 },
      { href: getPageParams(3), label: 3 },
      { href: getPageParams(4), label: 4 },
      { href: getPageParams(5), label: 5 },
      { label: "..." },
      { href: getPageParams(totalPages), label: totalPages },
    ];
  if (currentPage > 3 && currentPage <= totalPages - 5)
    return [
      { href: getPageParams(1), label: 1 },
      { label: "..." },
      { href: getPageParams(currentPage), label: currentPage },
      { href: getPageParams(currentPage + 1), label: currentPage + 1 },
      { href: getPageParams(currentPage + 2), label: currentPage + 2 },
      { label: "..." },
      { href: getPageParams(totalPages), label: totalPages },
    ];
  if (currentPage > totalPages - 5)
    return [
      { href: getPageParams(1), label: 1 },
      { label: "..." },
      { href: getPageParams(totalPages - 4), label: totalPages - 4 },
      { href: getPageParams(totalPages - 3), label: totalPages - 3 },
      { href: getPageParams(totalPages - 2), label: totalPages - 2 },
      { href: getPageParams(totalPages - 1), label: totalPages - 1 },
      { href: getPageParams(totalPages), label: totalPages },
    ];
  return [];
}

export function getItemsDividedToPages<T>(currentPage: number, itemsPerPage: number, arrayToPaginate: T[]) {
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return arrayToPaginate.slice(startIndex, endIndex);
}

export function getStartIndex(currentPage: number, itemsPerPage: number) {
  return currentPage * itemsPerPage + 1;
}

export function getEndIndex(currentPage: number, itemsPerPage: number) {
  return currentPage * itemsPerPage + itemsPerPage;
}
