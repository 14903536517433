import { useContext } from "react";
import { Close } from "@radix-ui/react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faTrashCan as faSolidTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useRevokeAccountMutation } from "../../../../api/hooks/useRevokeAccountMutation.ts";
import { Button, LinkButton, Modal } from "../../../../components";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import css from "./DeleteAccount.module.css";
import { useToast } from "../../../../components/Toast/useToast.ts";

export function DeleteAccount() {
  const revokeAccount = useRevokeAccountMutation();
  const { storage } = useContext(DataContext);
  const { showToast } = useToast();
  const deleteAccount = async () => {
    try {
      await revokeAccount.mutateAsync();
      window.location.reload();
    } catch (e) {
      showToast({ variant: "attention", title: "Smazat účet se nepodařilo", description: "Zkuste to znovu." });
    }
  };
  return (
    <Modal
      title={"Smazat veškerá data a přístupy"}
      icon={
        <FontAwesomeIcon className={css.icon} icon={faSolidTrashCan} width={"22px"} height={"22px"} color={"#d92d20"} />
      }
      trigger={
        <LinkButton
          className={css.dropdownItem}
          variant={"destructive"}
          color={"secondary"}
          size={"md"}
          endIcon={<FontAwesomeIcon icon={faTrashCan} width={20} height={20} />}
        >
          Smazat data a přístupy
        </LinkButton>
      }
    >
      <p className={css.modalText}>Opravdu chcete smazat veškerá data, přístupy a resetovat účet.</p>
      <p className={css.modalText}>Účet lze znovu aktivovat "Obnovením hesla".</p>

      <div className={css.buttonGroup}>
        <Close asChild>
          <Button variant={"default"} color={"gray"} size={"md"}>
            Zavřít
          </Button>
        </Close>

        <Button
          variant={"destructive"}
          color={"primary"}
          size={"md"}
          endIcon={<FontAwesomeIcon icon={faSolidTrashCan} />}
          onClick={() => {
            void deleteAccount();
            storage?.clearStoredValue();
          }}
        >
          Smazat
        </Button>
      </div>
    </Modal>
  );
}
