import { useMutation } from "@tanstack/react-query";
import { api } from "../ky";
import { getApiUrl } from "../utils.ts";

export function useRevokeAccountMutation() {
  return useMutation({
    mutationFn: async () => {
      return api.delete(getApiUrl("/multibank/users/me/revokeAll"));
    },
  });
}
