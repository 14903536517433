import { useMutation } from "@tanstack/react-query";
import { getApiUrl } from "../utils.ts";
import { ApiError, TokensResponse, validateTokensResponse } from "../types.ts";
import { api } from "../ky.ts";

export function useTokensMutation() {
  return useMutation<TokensResponse, ApiError, { promoCode: string }>({
    mutationFn: async ({ promoCode }) => {
      return validateTokensResponse(
        await api.get(getApiUrl("/multibank/users/me/tokens"), { headers: { "X-Promo-Code": promoCode } }).json(),
      );
    },
  });
}
