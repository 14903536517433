import { useToast } from "../components/Toast/useToast.ts";
import { useCallback, useRef } from "react";

export function useAccountCheck() {
  const { showToast } = useToast();
  const shownNotificationList = useRef<string[]>([]);

  const showNotification = useCallback(
    (hasAccounts: boolean, providerName: string) => {
      if (!hasAccounts && !shownNotificationList.current.includes(providerName)) {
        showToast({
          variant: "neutral",
          title: `${providerName}`,
          description: "Nebyly nalezeny žádné účty. Zkuste banku znovu připojit.",
        });
        shownNotificationList.current.push(providerName);
      }
    },
    [showToast],
  );

  return { showNotification };
}
