import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { ERoutes } from "./routes.ts";
import { Layout } from "../layout";
import { Dashboard } from "../pages/Dashboard";
import { Transactions } from "../pages/Transactions";
import { Requests } from "../pages/Requests";
import { Login } from "../pages/Login";

const router = createBrowserRouter([
  {
    path: ERoutes.Home,
    element: <Layout />,
    children: [
      { path: ERoutes.Home, element: <Dashboard />, errorElement: <Dashboard /> },
      { path: ERoutes.Transactions, element: <Transactions /> },
      { path: ERoutes.Requests, element: <Requests /> },
      { path: "*", element: <Navigate to={ERoutes.Home} replace={true} /> },
    ],
  },
  { path: ERoutes.Login, element: <Login /> },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
