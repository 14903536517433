import { PaymentCount } from "../../../../dataProviders/DataProvider.tsx";
import { TransactionType } from "./LargestIncomingPayment.tsx";
import { Card, ScrollArea } from "../../../../components";
import { formatNumber } from "../../../../utils/format.ts";

interface Props {
  dataKeys: string[];
  dataType: TransactionType;
  paymentCount: PaymentCount;
  currency: string;
  headline: string;
}

export function LargestPaymentTable({ dataKeys, dataType, paymentCount, currency, headline }: Props) {
  if (Object.keys(paymentCount).length === 0) {
    return (
      <Card size={"md"} variant={"dark"} className={"flex flex-col lg:col-span-5"}>
        <h3 className="typo-display-xxs-semibold text-gray-900 mb-4">{headline}</h3>
        <div className={"flex flex-col justify-center items-center grow"}>
          <p className={"max-w-52 text-center"}>Pro dané období nejsou k&nbsp;dispozici žádná data</p>
        </div>
      </Card>
    );
  }
  return (
    <Card size={"md"} variant={"dark"} className={"lg:col-span-5"}>
      <h3 className="typo-display-xxs-semibold text-gray-900 mb-4">{headline}</h3>
      <ScrollArea orientation={"horizontal"} color={"dark"}>
        <table className={"w-full"}>
          {dataKeys.map((key, index) => {
            const value = paymentCount[key];
            return (
              <tbody key={`${key}-${value.amount}-${index}`}>
                <tr className={"pb-3 border border-b-gray-300"}>
                  <td className={"typo-text-md-regular py-3"}>{value.label}</td>
                  <td className={"typo-text-md-regular py-3 pl-4"}>{dataType === "amount" ? currency : ""}</td>
                  <td align={"right"} className={"typo-text-md-semibold py-3 pl-4"}>
                    {dataType === "amount" ? formatNumber(value.amount) : value.quantity}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </ScrollArea>
    </Card>
  );
}
