import { useMutation } from "@tanstack/react-query";
import { api } from "../ky.ts";
import { getApiUrl } from "../utils.ts";

export function useRevokeProviderMutation() {
  return useMutation({
    mutationFn: async (token: string) =>
      await api.delete(getApiUrl("/multibank/users/me/providers/{paymentProvider}/revoke", { paymentProvider: token })),
  });
}
