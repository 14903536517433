import { clsx } from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeteor } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button/Button.tsx";
interface Props {
  message: string;
  grow: boolean;
  className?: string;
}

export function QueryError({ message, grow, className }: Props) {
  return (
    <div className={clsx("flex flex-col py-12 px-28 items-center justify-center gap-8", grow && "grow", className)}>
      <FontAwesomeIcon className={"h-7 text-error-600 animate-bounce"} icon={faMeteor} />
      <p className={"typo-display-sm-medium text-center text-gray-900"}>{message}</p>
      <Button variant={"default"} color={"secondary"} size={"lg"} onClick={() => window.location.reload()}>
        Obnovit stránku
      </Button>
    </div>
  );
}
