import { ApiError, GetAccountListWithBalanceResponse, validateGetAccountListResponse } from "../types.ts";
import { getApiUrl } from "../utils.ts";
import { api } from "../ky.ts";
import { useQueries, UseQueryOptions } from "@tanstack/react-query";

export const ACCOUNT_LIST_KEY = "accountList";
export const useAccountsQuery = (
  paymentProviders: string[],
  options?: Omit<UseQueryOptions<GetAccountListWithBalanceResponse, ApiError>, "queryFn" | "queryKey">,
) => {
  return useQueries({
    queries: paymentProviders.map((provider) => ({
      queryKey: [ACCOUNT_LIST_KEY, provider],
      queryFn: async () => sanitizedFetch(provider),
      ...options,
    })),
    combine: (result) => ({
      data: result.map(({ data }) => data ?? []),
      errors: result.map(({ error }) => error ?? []),
      isPending: result.some(({ isPending }) => isPending),
      isError: result.some(({ isError }) => isError),
    }),
  });
};

async function sanitizedFetch(paymentProvider: string): Promise<GetAccountListWithBalanceResponse> {
  try {
    return validateGetAccountListResponse(
      await api.get(getApiUrl(`/multibank/users/me/providers/{paymentProvider}/accounts`, { paymentProvider })).json(),
    );
  } catch (e) {
    return [];
  }
}
