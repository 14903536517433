import React from "react";
import ReactDOM from "react-dom/client";
import { initSentry } from "./utils/sentry.ts";
import App from "./App.tsx";
import { keycloak, keycloakInit } from "./auth/keycloak.ts";
import { useApiMock } from "./utils/config.ts";
import "./styles/index.css";

async function enableMocking() {
  if (!useApiMock) return;

  const { worker } = await import("./mock/browser.ts");
  return worker.start({
    onUnhandledRequest: "bypass",
  });
}

initSentry();

void keycloakInit();

keycloak.onReady = () => {
  enableMocking().then(() => {
    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  });
};
