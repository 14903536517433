import { LocalizationProvider } from "@mui/x-date-pickers";
import { csCZ } from "@mui/x-date-pickers/locales";
import { cs } from "date-fns/locale/cs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ErrorBoundary } from "@sentry/react";
import { Router } from "./router/Router.tsx";
import { ApiProvider } from "./api";
import { QueryError } from "./components/QueryError.tsx";
import { ToastProvider } from "./components/Toast/ToastProvider.tsx";

function App() {
  return (
    <ErrorBoundary fallback={<QueryError message={"Omlouváme se, došlo k neočekávané chybě."} grow={true} />}>
      <ApiProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={cs}
          localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <ToastProvider>
            <Router />
          </ToastProvider>
        </LocalizationProvider>
      </ApiProvider>
    </ErrorBoundary>
  );
}

export default App;
