import { Root, Trigger, Portal, Content, Title, Description, Overlay } from "@radix-ui/react-dialog";
import { useNavigate } from "react-router-dom";
import { IconButton, LinkButton } from "../../../../components";
import { MenuItem } from "../../Header.tsx";
import css from "./MenuMobile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { UserInfo } from "../UserInfo/UserInfo.tsx";
import { LogoutButton } from "../LogoutButton/LogoutButton.tsx";
import { useState } from "react";
import { DeleteAccount } from "../DeleteAccount/DeleteAccount.tsx";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

interface Props {
  items: MenuItem[];
  fullName: string;
  email: string;
}

export function MenuMobile({ items, fullName, email }: Props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const activeIcon = isOpen ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />;

  return (
    <Root onOpenChange={setIsOpen} open={isOpen}>
      <Trigger asChild>
        <IconButton
          icon={activeIcon}
          color={"primary"}
          size={"sm"}
          variant={"default"}
          title={"menu icon"}
          className={"sm:!hidden"}
        />
      </Trigger>
      <Portal aria-describedby={"Mobilní menu"}>
        <Overlay />
        <Content className={css.content} aria-describedby={"Mobilní menu"}>
          <VisuallyHidden asChild>
            <Description>Mobilní menu</Description>
          </VisuallyHidden>
          <VisuallyHidden asChild>
            <Title>Mobilní menu</Title>
          </VisuallyHidden>

          <div className={css.userSection}>
            <UserInfo fullName={fullName} email={email} />
            <LogoutButton />
          </div>
          <menu className={css.menu}>
            <ul className={css.list}>
              {items.map((item) => (
                <li key={item.text}>
                  <LinkButton
                    size={"md"}
                    color={"secondary"}
                    variant={"default"}
                    className={css.link}
                    onClick={() => {
                      navigate(item.href);
                      setIsOpen(false);
                    }}
                  >
                    {item.text}
                  </LinkButton>
                </li>
              ))}
            </ul>
          </menu>

          <div className={css.deleteAccount}>
            <DeleteAccount />
          </div>
        </Content>
      </Portal>
    </Root>
  );
}
