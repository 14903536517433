import { logError } from "../utils/sentry.ts";

export function formatResponse(value: unknown): string {
  try {
    return JSON.stringify(value, null, 4);
  } catch (e) {
    logError(e);
    return "";
  }
}
