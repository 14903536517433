import { operations } from "./schemaFinbricks.ts";
import { paths } from "./schema.ts";
import { HTTPError } from "ky";
import {
  array,
  assert,
  boolean,
  Describe,
  enums,
  literal,
  number,
  optional,
  string,
  StructError,
  type,
} from "superstruct";
import { logValidationError } from "../utils/sentry.ts";

export type ApiError = HTTPError | StructError;
export type ApiEndpoint = keyof paths;

export type PaymentProvidersResponse =
  operations["statusPaymentProviders"]["responses"]["200"]["content"]["application/json"];

const PaymentProvidersResponseStruct: Describe<PaymentProvidersResponse> = type({
  finbricks: optional(
    type({
      status: optional(
        type({
          plannedOutages: optional(
            array(
              type({
                from: optional(string()),
                to: optional(string()),
                description: optional(string()),
              }),
            ),
          ),
        }),
      ),
    }),
  ),
  banks: array(
    type({
      identification: type({
        name: string(),
        paymentProvider: string(),
        bankCode: optional(string()),
        countryCode: string(),
        bic: optional(string()),
        logoUrl: optional(string()),
      }),
      status: optional(
        type({
          enabledForMerchant: optional(boolean()),
          currentStatus: optional(enums(["online", "offline-planned", "offline-error"])),
          currentServiceStatus: optional(
            array(
              type({
                service: optional(
                  enums([
                    "AISP",
                    "PISP",
                    "PISP_INSTANT_PAYMENTS",
                    "BANK_ID",
                    "SPLIT_PAYMENT",
                    "RECURRING_PAYMENT",
                    "BATCH_PAYMENT",
                  ]),
                ),
                status: optional(enums(["online", "offline-planned", "offline-error"])),
              }),
            ),
          ),
          plannedOutages: optional(
            array(
              type({
                services: optional(
                  array(
                    enums([
                      "AISP",
                      "PISP",
                      "PISP_INSTANT_PAYMENTS",
                      "BANK_ID",
                      "SPLIT_PAYMENT",
                      "RECURRING_PAYMENT",
                      "BATCH_PAYMENT",
                    ]),
                  ),
                ),
                from: optional(string()),
                to: optional(string()),
                description: optional(string()),
              }),
            ),
          ),
        }),
      ),
    }),
  ),
});

export function validatePaymentProvidersResponse(data: unknown): PaymentProvidersResponse {
  return logValidationError(() => {
    assert(data, PaymentProvidersResponseStruct);
    return data;
  });
}

export type TokensResponse = operations["getRefreshTokens"]["responses"]["200"]["content"]["application/json"];
const TokensResponseStruct: Describe<TokensResponse> = array(
  type({
    clientId: string(),
    provider: string(),
    scope: string(),
    validFrom: string(),
    validTo: string(),
    stronglyAuthenticatedTo: optional(string()),
  }),
);
export function validateTokensResponse(data: unknown): TokensResponse {
  return logValidationError(() => {
    assert(data, TokensResponseStruct);
    return data;
  });
}

export type ProviderAuthorizeQuery =
  paths["/multibank/users/me/providers/{paymentProvider}/authorize"]["post"]["parameters"]["query"];

export type ProviderAuthorizeResponse =
  operations["createAuthenticationV2"]["responses"]["200"]["content"]["application/json"];
const ProviderAuthorizeResponseStruct: Describe<ProviderAuthorizeResponse> = type({
  redirectUrl: string(),
  operationId: optional(string()),
});
export function validateProviderAuthorizeResponse(data: unknown): ProviderAuthorizeResponse {
  return logValidationError(() => {
    assert(data, ProviderAuthorizeResponseStruct);
    return data;
  });
}

export type AccountTransactionsResponse =
  operations["accountTransactionsGet"]["responses"]["200"]["content"]["application/json"];
const AccountTransactionsResponseStruct: Describe<AccountTransactionsResponse> = type({
  pageNumber: optional(number()),
  pageCount: optional(number()),
  pageSize: optional(number()),
  nextPage: optional(number()),
  links: optional(
    array(
      type({
        rel: optional(literal("nextPage")),
        value: optional(string()),
      }),
    ),
  ),
  transactions: array(
    type({
      fbxReference: optional(string()),
      entryReference: optional(string()),
      amount: type({
        value: number(),
        currency: optional(string()),
      }),
      creditDebitIndicator: optional(enums(["DBIT", "CRDT"])),
      reversalIndicator: optional(boolean()),
      status: optional(string()),
      bookingDate: optional(
        type({
          date: optional(string()),
        }),
      ),
      valueDate: optional(
        type({
          date: optional(string()),
        }),
      ),
      bankTransactionCode: optional(
        type({
          proprietary: optional(
            type({
              code: optional(string()),
              issuer: optional(string()),
            }),
          ),
        }),
      ),
      entryDetails: optional(
        type({
          transactionDetails: optional(
            type({
              references: optional(
                type({
                  messageIdentification: optional(string()),
                  accountServicerReference: optional(string()),
                  paymentInformationIdentification: optional(string()),
                  instructionIdentification: optional(string()),
                  endToEndIdentification: optional(string()),
                  mandateIdentification: optional(string()),
                  chequeNumber: optional(string()),
                  clearingSystemReference: optional(string()),
                }),
              ),
              amountDetails: optional(
                type({
                  instructedAmount: optional(
                    type({
                      amount: optional(
                        type({
                          value: number(),
                          currency: optional(string()),
                        }),
                      ),
                    }),
                  ),
                  transactionAmount: optional(
                    type({
                      amount: optional(
                        type({
                          value: number(),
                          currency: optional(string()),
                        }),
                      ),
                    }),
                  ),
                  counterValueAmount: optional(
                    type({
                      amount: optional(
                        type({
                          value: number(),
                          currency: optional(string()),
                        }),
                      ),
                      currencyExchange: optional(
                        type({
                          sourceCurrency: optional(string()),
                          targetCurrency: optional(string()),
                          exchangeRate: optional(number()),
                        }),
                      ),
                    }),
                  ),
                  proprietaryAmount: optional(
                    type({
                      type: optional(string()),
                      amount: optional(
                        type({
                          value: number(),
                          currency: optional(string()),
                        }),
                      ),
                    }),
                  ),
                }),
              ),
              charges: optional(
                type({
                  bearer: optional(string()),
                }),
              ),
              relatedParties: optional(
                type({
                  debtor: optional(
                    type({
                      name: optional(string()),
                      postalAddress: optional(
                        type({
                          streetName: optional(string()),
                          buildingNumber: optional(string()),
                          postCode: optional(string()),
                          townName: optional(string()),
                          country: optional(string()),
                          addressLine: optional(string()),
                        }),
                      ),
                      identification: optional(
                        type({
                          organisationIdentification: optional(
                            type({
                              bicOrBei: optional(string()),
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                          privateIdentification: optional(
                            type({
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                  debtorAccount: optional(
                    type({
                      identification: optional(
                        type({
                          iban: optional(string()),
                          other: optional(
                            type({
                              identification: optional(string()),
                            }),
                          ),
                        }),
                      ),
                      currency: optional(string()),
                      name: optional(string()),
                    }),
                  ),
                  ultimateDebtor: optional(
                    type({
                      name: optional(string()),
                      postalAddress: optional(
                        type({
                          streetName: optional(string()),
                          buildingNumber: optional(string()),
                          postCode: optional(string()),
                          townName: optional(string()),
                          country: optional(string()),
                          addressLine: optional(string()),
                        }),
                      ),
                      identification: optional(
                        type({
                          organisationIdentification: optional(
                            type({
                              bicOrBei: optional(string()),
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                          privateIdentification: optional(
                            type({
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                  creditor: optional(
                    type({
                      name: optional(string()),
                      postalAddress: optional(
                        type({
                          streetName: optional(string()),
                          buildingNumber: optional(string()),
                          postCode: optional(string()),
                          townName: optional(string()),
                          country: optional(string()),
                          addressLine: optional(string()),
                        }),
                      ),
                      identification: optional(
                        type({
                          organisationIdentification: optional(
                            type({
                              bicOrBei: optional(string()),
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                          privateIdentification: optional(
                            type({
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                  creditorAccount: optional(
                    type({
                      identification: optional(
                        type({
                          iban: optional(string()),
                          other: optional(
                            type({
                              identification: optional(string()),
                            }),
                          ),
                        }),
                      ),
                      currency: optional(string()),
                      name: optional(string()),
                    }),
                  ),
                  ultimateCreditor: optional(
                    type({
                      name: optional(string()),
                      postalAddress: optional(
                        type({
                          streetName: optional(string()),
                          buildingNumber: optional(string()),
                          postCode: optional(string()),
                          townName: optional(string()),
                          country: optional(string()),
                          addressLine: optional(string()),
                        }),
                      ),
                      identification: optional(
                        type({
                          organisationIdentification: optional(
                            type({
                              bicOrBei: optional(string()),
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                          privateIdentification: optional(
                            type({
                              other: optional(
                                type({
                                  identification: optional(string()),
                                  schemeName: optional(
                                    type({
                                      code: optional(string()),
                                      proprietary: optional(string()),
                                      issuer: optional(string()),
                                    }),
                                  ),
                                }),
                              ),
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                  proprietary: optional(
                    type({
                      type: optional(string()),
                      party: optional(
                        type({
                          name: optional(string()),
                        }),
                      ),
                    }),
                  ),
                }),
              ),
              relatedAgents: optional(
                type({
                  debtorAgent: optional(
                    type({
                      financialInstitutionIdentification: optional(
                        type({
                          bic: optional(string()),
                          clearingSystemMemberIdentification: optional(
                            type({
                              clearingSystemIdentification: optional(
                                type({
                                  code: optional(string()),
                                  proprietary: optional(string()),
                                }),
                              ),
                              memberIdentification: optional(string()),
                            }),
                          ),
                          name: optional(string()),
                          postalAddress: optional(
                            type({
                              streetName: optional(string()),
                              buildingNumber: optional(string()),
                              postCode: optional(string()),
                              townName: optional(string()),
                              country: optional(string()),
                              addressLine: optional(string()),
                            }),
                          ),
                          other: optional(
                            type({
                              identification: optional(string()),
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                  creditorAgent: optional(
                    type({
                      financialInstitutionIdentification: optional(
                        type({
                          bic: optional(string()),
                          clearingSystemMemberIdentification: optional(
                            type({
                              clearingSystemIdentification: optional(
                                type({
                                  code: optional(string()),
                                  proprietary: optional(string()),
                                }),
                              ),
                              memberIdentification: optional(string()),
                            }),
                          ),
                          name: optional(string()),
                          postalAddress: optional(
                            type({
                              streetName: optional(string()),
                              buildingNumber: optional(string()),
                              postCode: optional(string()),
                              townName: optional(string()),
                              country: optional(string()),
                              addressLine: optional(string()),
                            }),
                          ),
                          other: optional(
                            type({
                              identification: optional(string()),
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                }),
              ),
              purpose: optional(
                type({
                  code: optional(string()),
                  proprietary: optional(string()),
                }),
              ),
              remittanceInformation: optional(
                type({
                  unstructured: optional(string()),
                  structured: optional(
                    type({
                      creditorReferenceInformation: optional(
                        type({
                          reference: optional(string()),
                        }),
                      ),
                    }),
                  ),
                }),
              ),
              additionalTransactionInformation: optional(string()),
              additionalRemittanceInformation: optional(string()),
            }),
          ),
        }),
      ),
    }),
  ),
});
export function validateAccountTransactionsResponse(data: unknown): AccountTransactionsResponse {
  return logValidationError(() => {
    assert(data, AccountTransactionsResponseStruct);
    return data;
  });
}

export type GetAccountListWithBalanceResponse =
  operations["accountListWithBalanceGet"]["responses"]["200"]["content"]["application/json"];
const AccountListWithBalanceResponseStruct: Describe<GetAccountListWithBalanceResponse> = array(
  type({
    id: optional(string()),
    identification: optional(
      type({
        accountNumber: optional(string()),
        iban: optional(string()),
        other: optional(
          array(type({ type: optional(enums(["CARD_NUMBER", "PHONE", "EMAIL", "TAXID"])), value: optional(string()) })),
        ),
      }),
    ),
    balance: number(),
    currency: optional(string()),
    balanceType: optional(string()),
    accountName: optional(string()),
    productName: optional(string()),
    ownersNames: optional(array(string())),
    pispSuitable: optional(boolean()),
    dateTime: optional(string()),
    bic: optional(string()),
  }),
);
export function validateGetAccountListResponse(data: unknown): GetAccountListWithBalanceResponse {
  return logValidationError(() => {
    assert(data, AccountListWithBalanceResponseStruct);
    return data;
  });
}
