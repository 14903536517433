import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";
import css from "./LinkButton.module.css";
import { clsx } from "clsx";

type Variant = "default" | "destructive";
type Color = "primary" | "secondary";
type Size = "md" | "lg" | "xl";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: Variant;
  color: Color;
  size: Size;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const LinkButton = forwardRef<HTMLButtonElement, Props>(function LinkButton(
  { variant, color, size, children, startIcon, endIcon, className, ...props },
  ref,
) {
  return (
    <button
      className={clsx(css.button, variantMap[variant], colorMap[color], sizeMap[size], className)}
      {...props}
      ref={ref}
    >
      {startIcon && <div className={css.iconContainer}>{startIcon}</div>}
      {children}
      {endIcon && <div className={css.iconContainer}>{endIcon}</div>}
    </button>
  );
});

const variantMap: Record<Variant, string> = {
  default: css.default,
  destructive: css.destructive,
};

const colorMap: Record<Color, string> = {
  primary: css.primary,
  secondary: css.secondary,
};

const sizeMap: Record<Size, string> = {
  md: css.md,
  lg: css.lg,
  xl: css.xl,
};
