import { NavLink } from "react-router-dom";
import { clsx } from "clsx";
import { MenuItem } from "../../Header.tsx";
import css from "../../../../components/Tabs/Tabs.module.css";

interface Props {
  items: MenuItem[];
}

export function MenuDesktop({ items }: Props) {
  return (
    <menu className={clsx("hidden sm:flex", css.textContainer)}>
      {items.map((item) => (
        <NavLink
          key={item.text}
          className={({ isActive }) => {
            return clsx(css.text, isActive && css.textActive);
          }}
          to={item.href}
        >
          {item.text}
        </NavLink>
      ))}
    </menu>
  );
}
