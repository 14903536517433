import { DataContext, StructuredData } from "../../../../dataProviders/DataProvider.tsx";
import { useContext, useMemo } from "react";
import { PieChart } from "@mui/x-charts";
import { ChartTooltip } from "../ChartsTooltip.tsx";
import css from "./BalanceOverviewChart.module.css";
import { noDataMessage } from "../../utils/chartsInfo.ts";
import { formatNumber } from "../../../../utils/format.ts";
import { logError } from "../../../../utils/sentry.ts";

type Props = {
  structuredData: StructuredData;
  currency: string;
};

type BalanceOverviewData = { label: string; value: number };

const DISABLED_ITEM_OPACITY = "33";

export function BalanceOverviewChart({ structuredData, currency }: Props) {
  const { selectedCountries, listOfActiveAccounts, activeBanks } = useContext(DataContext);
  const { banksData, accountsData, accountsColors, banksColors } = useMemo(() => {
    const banksData: BalanceOverviewData[] = [];
    const accountsData: BalanceOverviewData[] = [];
    const banksColors: string[] = [];
    const accountsColors: string[] = [];

    try {
      selectedCountries.forEach((country) => {
        const currencies = structuredData[country][currency]?.providers ?? {};
        Object.keys(currencies).forEach((provider) => {
          const accounts = structuredData[country][currency].providers[provider].accounts;
          Object.keys(accounts).forEach((account) => {
            !listOfActiveAccounts.includes(account)
              ? accountsColors.push(accounts[account].color + DISABLED_ITEM_OPACITY)
              : accountsColors.push(accounts[account].color);

            accountsData.push({
              label: accounts[account].identification?.accountNumber ?? "",
              value: accounts[account].balance,
            });
          });

          !activeBanks.includes(provider)
            ? banksColors.push(currencies[provider].color + DISABLED_ITEM_OPACITY)
            : banksColors.push(currencies[provider].color);
          banksData.push({
            label: currencies[provider].info?.identification?.name ?? provider,
            value: currencies[provider].balance,
          });
        });
      });

      return { banksData, accountsData, accountsColors, banksColors };
    } catch (error) {
      logError(error);
      return { banksData, accountsData, accountsColors, banksColors };
    }
  }, [listOfActiveAccounts, activeBanks, currency, selectedCountries, structuredData]);

  const bankSeries = [
    {
      innerRadius: 80,
      outerRadius: 150,
      paddingAngle: 0.25,
      cornerRadius: 13,
      startAngle: 45,
      endAngle: 405,
      id: "series-1",
      data: banksData,
    },
  ];

  const accountSeries = [
    {
      innerRadius: 151,
      outerRadius: 220,
      paddingAngle: 0.25,
      cornerRadius: 13,
      startAngle: 45,
      endAngle: 405,
      id: "series-2",
      data: accountsData,
    },
  ];

  return (
    <div className={css.chartContainer}>
      <PieChart
        series={accountSeries}
        width={444}
        height={444}
        slotProps={{ legend: { hidden: true }, noDataOverlay: { message: noDataMessage } }}
        margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
        colors={accountsColors}
        slots={{
          itemContent: (props) => {
            if (!accountsData[props.itemData.dataIndex]) return null;
            return (
              <ChartTooltip
                value={formatNumber(accountsData[props.itemData.dataIndex].value)}
                label={accountsData[props.itemData.dataIndex].label}
                currency={currency}
              />
            );
          },
        }}
      />

      <PieChart
        series={bankSeries}
        width={308}
        height={308}
        slotProps={{ legend: { hidden: true }, noDataOverlay: { message: noDataMessage } }}
        margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
        colors={banksColors}
        slots={{
          itemContent: (props) => {
            return (
              <ChartTooltip
                value={formatNumber(banksData[props.itemData.dataIndex].value)}
                label={banksData[props.itemData.dataIndex].label}
                currency={currency}
              />
            );
          },
        }}
      />
    </div>
  );
}
