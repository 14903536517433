import css from "./Divider.module.css";
import { clsx } from "clsx";
import { HTMLAttributes } from "react";

type Variant = "horizontal" | "vertical";
type Color = "default" | "dark";

interface Props extends HTMLAttributes<HTMLHRElement> {
  variant?: Variant;
  color?: Color;
}

export function Divider({ variant = "horizontal", color = "default", className, ...props }: Props) {
  return <hr className={clsx(variantMap[variant], colorMap[color], className)} {...props} />;
}

const variantMap: Record<Variant, string> = {
  horizontal: css.horizontalDivider,
  vertical: css.verticalDivider,
};

const colorMap: Record<Color, string> = {
  default: css.default,
  dark: css.dark,
};
