import { Root, Indicator } from "@radix-ui/react-checkbox";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import css from "./Checkbox.module.css";
import { clsx } from "clsx";

type Size = "sm" | "md";

interface Props {
  name: string;
  value: string;
  size: Size;
  onCheckedChange: () => void;
  checked: boolean;
  isDisabled?: boolean;
}

export function Checkbox({ name, value, checked, onCheckedChange, size, isDisabled = false }: Props) {
  return (
    <div className={"inline-flex items-center"}>
      <Root
        aria-label="aktivní položka"
        disabled={isDisabled}
        onCheckedChange={onCheckedChange}
        checked={checked}
        name={name}
        value={value}
        className={clsx(css.checkbox, sizeMap[size])}
      >
        <Indicator className={clsx(css.checkboxIndicator)}>
          <FontAwesomeIcon className={iconSizeMap[size]} icon={faCheck} />
        </Indicator>
      </Root>
    </div>
  );
}

const sizeMap: Record<Size, string> = {
  sm: css.sm,
  md: css.md,
};

const iconSizeMap: Record<Size, string> = {
  sm: css.iconSm,
  md: css.iconMd,
};
