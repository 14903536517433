interface Props {
  value: string;
  label: string;
  currency: string;
}

export function ChartTooltip({ value, label, currency }: Props) {
  return (
    <div className={"ml-4 rounded-lg bg-gray-25 p-4 w-48 shadow-[0px_2px_16px_2px_rgba(12,_11,_50,_0.15)]"}>
      <p className={"typo-text-sm-regular text-gray-500 mb-2"}>{label}</p>
      <div className="flex items-center justify-between">
        <p className={"typo-text-xl-bold text-gray-700"}>{value}</p>
        <span className={"typo-text-lg-medium text-gray-700"}>{currency}</span>
      </div>
    </div>
  );
}
