import { useCallback, useContext, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { UrlParamsContext } from "./UrlParamsProvider";

export function useUrlParams() {
  const { params, setParams } = useContext(UrlParamsContext);
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const location = useLocation();
  const state = useMemo<unknown>(() => location.state, [location.state]);

  const get = useCallback((name: string) => paramsRef.current.get(name), []);

  const getAll = useCallback((name: string) => paramsRef.current.getAll(name), []);

  const set = useCallback(
    (name: string, value: string, replace: boolean = true) => {
      paramsRef.current.set(name, value);
      paramsRef.current.sort();

      setParams(paramsRef.current, { replace, state: replace ? state : undefined });
    },
    [setParams, state],
  );

  const setArray = useCallback(
    (name: string, values: string[], replace: boolean = true) => {
      paramsRef.current.delete(name);
      values.forEach((value) => paramsRef.current.append(name, value));
      paramsRef.current.sort();

      setParams(paramsRef.current, { replace, state: replace ? state : undefined });
    },
    [setParams, state],
  );

  const remove = useCallback(
    (name: string, replace: boolean = true) => {
      paramsRef.current.delete(name);
      paramsRef.current.sort();

      setParams(paramsRef.current, { replace, state: replace ? state : undefined });
    },
    [setParams, state],
  );

  return useMemo(
    () => ({ get, getAll, set, setArray, remove, params, setParams }),
    [get, getAll, params, remove, set, setArray, setParams],
  );
}
