import { keycloak, useKeycloakUserQuery } from "../keycloak.ts";

export function useAuth() {
  const { data } = useKeycloakUserQuery();

  const login = async (): Promise<void> => {
    await keycloak.login();
  };

  const logout = async (): Promise<void> => {
    await keycloak.logout();
  };

  const isAuthenticated = keycloak.authenticated;
  const isLoadingUserData = !data;

  return { login, logout, isAuthenticated, isLoadingUserData };
}
