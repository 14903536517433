import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputProps, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { clsx } from "clsx";
import { Tooltip } from "./Tooltip/Tooltip.tsx";

interface Props {
  value: Date | null;
  text: string;
  onChange: (value: Date | null) => void;
  onClear: () => void;
  className?: string;
  size?: "sm" | "lg";
  label?: string;
  minDate?: Date;
  maxDate?: Date;
}

export default function CustomDatePicker({
  value,
  onChange,
  onClear,
  text,
  minDate,
  maxDate,
  label,
  className,
  size = "sm",
}: Props) {
  const [open, setOpen] = useState(false);
  return (
    <div className={clsx(className, "relative")}>
      {!!label && <p className={"typo-text-xs-regular text-gray-500 mb-0.5"}>{label}</p>}
      <DatePicker
        value={value}
        onChange={onChange}
        open={open}
        minDate={minDate}
        maxDate={maxDate}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        slots={{ textField: TextField }}
        format={"d.M.yyyy"}
        slotProps={{
          field: {
            onClick: () => setOpen((prev) => !prev),
            text: text,
            buttonSize: size,
            shouldRespectLeadingZeros: true,
            // eslint-disable-next-line
          } as any,
        }}
      />
      {!!value && (
        <Tooltip
          trigger={
            <button
              className={clsx("absolute top-0 right-8 p-2", size === "lg" && "top-6 right-12")}
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            >
              <FontAwesomeIcon className={"w-5 h-5 text-gray-500 hover:text-brand-700"} icon={faXmark} />
            </button>
          }
          text={"Smazat"}
        />
      )}
    </div>
  );
}

interface TextFieldProps {
  value: string;
  onClick: () => void;
  InputProps: InputProps;
  inputProps: MuiTextFieldProps;
  text: string;
  buttonSize: "sm" | "lg";
}

const TextField = ({ value, onClick, InputProps, inputProps, text, buttonSize }: TextFieldProps) => {
  return (
    <div
      // eslint-disable-next-line
      ref={InputProps.ref as any}
      onClick={onClick}
      className={clsx(
        "whitespace-nowrap rounded-lg py-2 px-3.5 w-full min-w-[165px] border border-gray-300 bg-gray-25 hover:border-brand-700 active:border-brand-700 flex gap-4 items-center cursor-pointer grow xl:grow-0",
        buttonSize === "lg" && "border-gray-300 h-12",
      )}
    >
      <p ref={inputProps.ref} className={"typo-text-md-regular text-gray-500"}>
        {value || text}
      </p>
      <FontAwesomeIcon className={"w-5 h-5 text-gray-700 ml-auto"} icon={faCalendar} />
    </div>
  );
};
