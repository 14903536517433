import css from "./UserInfo.module.css";

interface Props {
  fullName: string;
  email: string;
}

export function UserInfo({ email, fullName }: Props) {
  return (
    <div className={css.dropdownItem}>
      <p className={css.fullName}>{fullName}</p>
      <p className={css.email}>{email}</p>
    </div>
  );
}
