import { useMemo } from "react";
import { PaymentCount } from "../../../../dataProviders/DataProvider.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { Divider, ScrollArea, Select, Tabs } from "../../../../components";
import { axisClasses, AxisConfig, BarChart, ChartsYAxisProps } from "@mui/x-charts";
import { axisColor, noDataMessage, tickColor } from "../../utils/chartsInfo.ts";
import { Box } from "@mui/material";
import { TransactionType } from "./LargestOutgoingPayment.tsx";
import { ChartTooltip } from "../ChartsTooltip.tsx";
import { formatNumber } from "../../../../utils/format.ts";

interface Props {
  dataKeys: string[];
  dataType: TransactionType;
  setDataType: (type: TransactionType) => void;
  currency: string;
  timeRange: string;
  setTimeRange: (timeRange: string) => void;
  paymentCount: PaymentCount;
  headline: string;
  color: string;
}

type TabData = { value: TransactionType; text: string }[];

const LABEL_LENGTH = 20;

const tabData: TabData = [
  { value: "amount", text: "Celková částka" },
  { value: "quantity", text: "Počet transakcí" },
];
const selectData = [
  { value: "7", label: "7 dní" },
  { value: "30", label: "30 dní" },
  { value: "90", label: "90 dní" },
];

export function LargestPaymentChart({
  dataKeys,
  setDataType,
  dataType,
  paymentCount,
  timeRange,
  setTimeRange,
  currency,
  headline,
  color,
}: Props) {
  const selectValue = String(timeRange);
  const labelItem = dataType === "amount" ? currency : "";

  const dataset = useMemo(() => dataKeys.map((key) => ({ ...paymentCount[key], key })), [dataKeys, paymentCount]);

  return (
    <div className={"bg-gray-25 p-6 rounded-lg flex flex-col gap-6 border border-gray-200 lg:col-span-7"}>
      <div className={"flex gap-4"}>
        <FontAwesomeIcon className={"w-5 h-5 text-brand-700"} icon={faMoneyBillTransfer} />
        <h3 className={"typo-display-xs-semibold text-gray-900"}>{headline}</h3>
      </div>
      <div className={"flex gap-3 flex-wrap"}>
        {tabData.map((tab) => (
          <Tabs
            key={tab.value}
            startIcon={<FontAwesomeIcon className={"w-1.5 pt-0.5"} icon={faCircle} />}
            variant={"box"}
            onClick={() => setDataType(tab.value)}
            isActive={dataType === tab.value}
          >
            {tab.text}
          </Tabs>
        ))}
        <Select
          className={"ml-auto"}
          value={selectValue}
          variant={"primary"}
          onChange={setTimeRange}
          options={selectData}
        />
      </div>
      <Divider />
      <ScrollArea orientation={"horizontal"}>
        <Box sx={{ width: "100%", minWidth: "350px" }}>
          <BarChart
            dataset={dataset}
            yAxis={
              [
                {
                  id: "account",
                  dataKey: "key",
                  scaleType: "band",
                  categoryGapRatio: 0.7,
                  valueFormatter: (value) =>
                    paymentCount[value].label.length > LABEL_LENGTH
                      ? paymentCount[value].label.slice(0, LABEL_LENGTH - 3).concat("...")
                      : paymentCount[value].label,
                },
              ] as AxisConfig<"band", string, ChartsYAxisProps>[]
            }
            xAxis={[{ tickMinStep: 1 }]}
            bottomAxis={{ disableTicks: true, disableLine: true }}
            leftAxis={{ disableTicks: true, stroke: tickColor }}
            series={[{ dataKey: dataType, color: color }]}
            sx={{
              [`.${axisClasses.line}`]: { stroke: axisColor },
              [`.${axisClasses.tickLabel}`]: { fill: tickColor },
            }}
            layout="horizontal"
            grid={{ vertical: true }}
            borderRadius={999}
            height={213}
            margin={
              dataKeys.length === 0
                ? { left: 0, right: 0, top: 0, bottom: 0 }
                : { left: 140, right: 0, top: 0, bottom: 20 }
            }
            slotProps={{ noDataOverlay: { message: noDataMessage } }}
            slots={{
              axisContent: ({ axisValue }) => {
                if (typeof axisValue !== "string") return null;

                return (
                  <ChartTooltip
                    value={formatNumber(paymentCount[axisValue][dataType])}
                    label={paymentCount[axisValue].label}
                    currency={labelItem}
                  />
                );
              },
            }}
          />
        </Box>
      </ScrollArea>
    </div>
  );
}
